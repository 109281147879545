@import "../../../styles/mixins.scss";

.HomeModuleInfo {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media (max-width: $media1) {
    flex-direction: column;
  }

  .uzor {
    position: absolute;
    padding-top: 105px;
    width: 591.77px;
    height: 466.58px;
    left: 0;

    @media (max-width: $media1) {
      position: absolute;
      left: 0;
    }

    @media (max-width: $media2) {
      display: none;
    }

    @media (max-width: 1250px) {
      display: none;
    }

    img {
      transform: translateY(-33px);

      @media (max-width: $media1) {
        position: absolute;
        height: 316.72px;
        transform: translateY(-28px);
        left: -20px;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 1130px;
    padding-top: 0;
    margin-top: 0;
    padding-left: 387px;

    @media (max-width: $media1) {
      width: 750px;
      margin: 0 auto;
      transform: translateY(-93px);
      padding-left: 0;
    }

    @media (max-width: $media2) {
      width: auto;
      max-width: 375px;
      padding-left: 0;
    }

    h2 {
      font-size: 80px;
      font-weight: 400;
      line-height: 68.8px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      font-family: "SPSL-New-Cyrillic";
      color: #3e280e;
      padding: 0;
      margin: 0;
      padding-top: 28px;
      @media (max-width: $media1) {
        font-size: 50px;
        font-weight: 400;
        line-height: 43px;
        text-align: center;
      }

      @media (max-width: $media2) {
        font-size: 24px;
        font-weight: 400;
        line-height: 20.64px;
        margin-top: 195px;
      }
    }
    p {
      font-family: "Sitka";
      font-size: 32px;
      font-weight: 400;
      line-height: 40.04px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      color: #3e280e;
      padding: 0;
      margin: 0;
      margin-bottom: 36px;
      text-align: justify;
      margin-top: 50px;
      letter-spacing: -0.6px;

      @media (max-width: $media1) {
        font-size: 20px;
        font-weight: 400;
        line-height: 25.02px;
        margin-top: 30px;
        padding-left: 14px;
        padding-right: 37px;
        letter-spacing: 0px;
        margin-bottom: 22px;
      }

      @media (max-width: $media2) {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.02px;
        padding: 20px 18px;
        margin: 0;
        text-align: left;
        letter-spacing: 0.3px;
      }
    }
    .imgBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: $media1) {
        width: 922px;
        transform: translateX(-85px);
      }

      @media (max-width: $media2) {
        max-width: 375px;
        width: 100%;
        flex-direction: column;
        transform: translateX(0px);
      }

      img {
        width: 610px;
        height: 480px;
        border: 4px solid #341f07;
        border-radius: 10px;
        box-sizing: border-box;

        @media (max-width: $media1) {
          width: 454px;
          height: 353px;
        }

        @media (max-width: $media2) {
          margin: 0 auto;
          max-width: 339px;
          width: 100%;
          height: 227px;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: top;
          object-position: top;
          border: 2px solid #341f07;
        }
      }
      img:nth-child(1) {
        width: 480px;
        height: 480px;

        @media (max-width: $media1) {
          width: 454px;
          height: 353px;
          margin-right: 12px;
        }
        @media (max-width: $media2) {
          // width: 375px;
          max-width: 339px;
          width: 100%;
          height: 237px;
          margin-right: 0;
          margin: 0 auto;
          margin-bottom: 7px;
          border: 2px solid #341f07;
        }
      }
    }
  }
}
