@import "./../../styles/mixins.scss";

.NewsPageMoreContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
  padding-top: 90px;

  @media (max-width: 1729px) {
    padding-top: 30px;
  }

  @media (max-width: 928px) {
    padding-top: 0px;
  }

  h2 {
    font-family: SPSL-New-Cyrillic;
    font-size: 40px;
    font-weight: 400;
    line-height: 34.42px;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3e280e;

    @media (max-width: 1520px) {
      font-size: 30px;
      line-height: 25.82px;
    }
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 20.65px;
      text-align: left;
    }
  }
  .NewsPageMore {
    width: 1320px;
    margin-top: 50px;
    // margin-bottom: 58px;
    @media (max-width: 1520px) {
      width: 100%;
    }
    .ButtonBack {
      margin-top: 50px;

      @media (max-width: 1520px) {
        width: 80%;
        margin: 50px auto;
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        transition: 0.1s linear all;
        cursor: auto;
        &:hover {
          opacity: 0.6;
        }
      }
      button {
        font-family: "SPSL-New-Cyrillic";
        background-color: inherit;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3e280e;
        font-size: 40px;
        cursor: pointer;

        p {
          margin: 0;
          @media (max-width: 920px) {
            position: relative;
            top: 2px;
          }
        }
        @media (max-width: 1520px) {
          font-size: 28px;
        }
        @media (max-width: 920px) {
          background-color: #ecdabe;
          border-radius: 8px;
          padding: 9px 14px;
          font-size: 20px;
        }
        img {
          width: 49px;
          margin-right: 15px;
          @media (max-width: 920px) {
            width: 23px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .textInfo {
    padding-bottom: 40px;
    @media (max-width: 1520px) {
      width: 80%;
      margin: 0 auto;
    }
    p {
      color: #3e280e;
      text-align: justify;
      font-size: 26px;
      // font-family: "SPSL-New-Cyrillic";
      a {
        color: #341f07;
        transition: 0.1s linear all;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      @media (max-width: 1520px) {
        font-size: 16px;
      }
    }
    .date {
      display: flex;
      justify-content: end;
      width: 100%;
      p {
        background: linear-gradient(
          109.62deg,
          #341f07 8.21%,
          #c19c6f 65.81%,
          #341f07 140.62%
        );
        color: #fdf1dc;
        padding: 15px 19px;
        border-radius: 5px;
        font-family: "SPSL-New-Cyrillic";
        font-size: 40px;

        @media (max-width: 1520px) {
          padding: 5px 19px;
          font-size: 18px;
          margin: 0;
        }
      }
    }
  }
  .slider {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 120px;
    @media (max-width: 920px) {
      display: none;
    }
  }
  .imgsMobil {
    display: none;
    margin-bottom: 40px;

    @media (max-width: 920px) {
      display: flex;
      // margin-bottom: 100px;
      .imgsMobilInner {
        display: flex;
        width: 100%;
        padding: 0 10%;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: auto;

        img:first-child {
          margin-left: 0;
        }
        img:last-child {
          margin-right: 0;
        }
        img {
          object-fit: cover;
          width: 335px;
          height: 258px;
          margin: 10px;
          border: 2px solid #3e280e;
          border-radius: 10px;

          @media (max-width: 410px) {
            width: 90%;
            height: 208px;
          }
        }
        // Стили для скроллбара
        ::-webkit-scrollbar {
          width: 12px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;

          &:hover {
            background: #555;
          }
        }
      }
    }
  }
  .video {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
    img {
      width: 1000px;
      height: 700px;
      object-fit: cover;
      border: 2px solid #3e280e;
      border-radius: 10px;

      @media (max-width: 1520px) {
        width: 60vw;
        height: 40vw;
      }
      @media (max-width: 920px) {
        width: 335px;
        height: 258px;
      }
      @media (max-width: 410px) {
        width: 90%;
        height: 208px;
      }
    }
  }
  .Loader {
    display: flex;
    font-family: "Sitka";
    font-size: 2vw;
    font-weight: 400;
    text-align: left;
    color: #3e280e;
    margin-left: 4vw;
    justify-content: center;
    align-items: center;
    padding-top: 10vw;
  }

  .loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: rotate 1s linear infinite;
    animation: rotate 1s linear infinite;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 8px solid #3e280e;
      -webkit-animation: prixClipFix 2s linear infinite;
      animation: prixClipFix 2s linear infinite;
    }
    @-webkit-keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }

    @keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }
  }
}
