.today {
  display: none;
}
.fasting-message {
  margin-top: 40px;
}
@media (max-width: 1520px) {
  .fasting-message {
    margin-top: 15px;
  }
}
.holidaysNotData {
  color: #3e280e !important;
}
.fasting-message a {
  font-family: Sitka;
  font-size: 22px;
  font-weight: 400;
  line-height: 27.52px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e280e;
  text-decoration: underline;
}
hr {
  display: none;
}

.main-block ul {
  list-style: none;
  padding: 0;
}

.main-block p,
.main-block p span,
.main-block p span a,
.fasting-message,
.azbyka-days,
.today,
.main-block li a {
  pointer-events: none; /* Отключаем взаимодействие */
  color: inherit; /* Убираем цвет ссылки */
  text-decoration: none; /* Убираем подчеркивание */
  color: #3e280e;
  font-family: Sitka;
  font-size: 22px;
  font-weight: 400;
  line-height: 27.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: left;
}

@media (max-width: 750px) {
  .main-block p,
  .main-block p span,
  .main-block p span a,
  .fasting-message,
  .azbyka-days,
  .today,
  .main-block li a {
    font-family: Sitka;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.02px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.main-block img {
  display: none;
}

/* календарь  */

.calendarTamle .react-calendar__month-view__weekdays__weekday {
  height: 64px;
  margin: 10px 0;
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar__month-view__weekdays__weekday {
    height: 44px;
  }
}

.calendarTamle .react-calendar__month-view__weekdays__weekday span {
  color: #341f07;
  font-size: 21.33px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.6666666865348816px;
  text-align: center;
}

.calendarTamle .react-calendar__navigation__label span {
  color: #341f07;
  font-size: 18.67px;
  font-weight: 500;
  line-height: 26.67px;
  letter-spacing: 0.13333334028720856px;
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar__navigation__label span {
    font-size: 15px;
  }
}

.calendarTamle .react-calendar__navigation {
  margin-bottom: 10px;
}
.calendarTamle .react-calendar__navigation button {
  width: 53px;
  height: 53px;
  min-width: auto;
  font-size: 20px;
  padding: 0;
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar__navigation button {
    width: 45px;
    height: 45px;
  }
}

.calendarTamle .react-calendar__tile {
  border-radius: 50%;
  padding: 10px;
  font-size: 21px;
  line-height: normal;
}

.calendarTamle .react-calendar__tile--active abbr {
  background-color: #341f07 !important;
  border-radius: 50%;
}
.calendarTamle .react-calendar__tile--active {
  background-color: none;
  background: none;
}

.calendarTamle .react-calendar__navigation__label__labelText {
  color: #341f07;
}

.calendarTamle .react-calendar {
  padding: 16px;
  padding-bottom: 75px;
  background-color: #fff8ec;
  border-radius: 13px;
  border: 2px solid #3e280e;
  height: auto;
  box-sizing: border-box;
  width: 480px;
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar {
    width: 354px;
    padding-bottom: 45px;
  }
}

@media (max-width: 750px) {
  .calendarTamle .react-calendar {
    margin: 0 auto;
  }
}
@media (max-width: 750px) {
  .calendarTamle {
    display: none;
  }
  .calendarTamle.show {
    display: block;
  }
}

.calendarTamle .react-calendar button {
  margin: 5px 0;
  padding: 4px;
}

.calendarTamle .react-calendar button abbr {
  display: flex;
  justify-content: center;
  padding: 16px 10px;
  font-size: 23px;
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar button abbr {
    padding: 10px 8px;
    font-size: 15px;
  }
}

.calendarTamle .react-calendar__tile--now abbr {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 50%;
}

.calendarTamle .react-calendar__tile abbr:hover {
  background-color: #f0e3cc;
  /* color: #ffffff; */
  border-radius: 50%;
}

.calendarTamle .react-calendar__tile--now {
  background: none;
}
/* .react-calendar__month-view__days {
  line-height: 0.6em;
} */
.calendarTamle .react-calendar__month-view__weekdays__weekday {
  font-size: 20px;
  color: #bea881;
  display: flex;
  align-items: end;
  justify-content: center;
  abbr {
    outline: none;
    text-decoration: none;
  }
}

@media (max-width: 1520px) {
  .calendarTamle .react-calendar__month-view__weekdays__weekday {
    font-size: 13px;
  }
}
.calendar {
  position: relative;
  width: 100%;
  position: relative;
}

.calendarTamle .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}
.calendarTamle .react-calendar__tile:enabled:hover,
.calendarTamle .react-calendar__tile:enabled:focus {
  background: none;
}
.calendarTamle .react-calendar__tile--now {
  background: none;
}
.calendarTamle .react-calendar__tile--now:enabled:hover,
.calendarTamle .react-calendar__tile--now:enabled:focus {
  background: none;
}
