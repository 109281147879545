@import "./../../styles/mixins.scss";

.PardonModuleForm {
  display: flex;
  flex-direction: column;
  width: 1000px;
  min-height: 50vh;
  padding: 70px 130px;
  border: 0.2vw solid #845825;
  border-radius: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 95px;
  @media (max-width: 1910px) {
    width: 830px;
  }
  @media (max-width: 1730px) {
    width: 557px;
    min-height: 60vh;
    margin-top: 15px;
    padding: 40px;
  }
  @media (max-width: 930px) {
    border: none;
    min-height: auto;
    margin: 0 auto;
    padding: 20px 40px;
    width: 500px;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    width: 100%;
    padding: 20px 10px;
  }

  h3 {
    @include H3;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 2vw;
    line-height: normal;
    @media (max-width: 1730px) {
      font-size: 40px;
      display: none;
    }
  }
  .boxItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    .typse {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 70px;
      @media (max-width: 580px) {
        margin-bottom: 20px;
      }
      span:nth-child(1) {
        margin-right: 1%;
      }
      span:nth-child(2) {
        margin-left: 1%;
      }

      span {
        text-wrap: nowrap;
        // margin: 0 2%;
        width: 350px;
        padding: 12px 8px;
        display: flex;
        justify-content: center;
        border: 0.1vw solid #845825;
        border-radius: 10px;
        font-family: "SPSL-New-Cyrillic";
        font-size: 1.7vw;
        font-weight: 400;
        cursor: pointer;
        color: #3e280e;
        font-size: 40px;
        @media (max-width: 1730px) {
          font-size: 32px;
          padding: 11px 8px;
          width: 242px;
        }
        &:nth-child(1) {
          @media (max-width: 580px) {
            width: 50%;
            margin: 0 10px 0px 0px;
            height: 49px;
            padding: 0 8px;
            display: flex;
            align-items: center;
          }
        }
        &:nth-child(2) {
          @media (max-width: 580px) {
            width: 50%;
            margin: 0px 0px 0px 10px;
            height: 49px;
            padding: 0;
            display: flex;
            align-items: center;
          }
        }
        @media (max-width: 930px) {
          font-size: 28px;
        }
        @media (max-width: 530px) {
          font-size: 22px;
        }

        // &:hover {
        //   color: #ebddc4;
        //   background: linear-gradient(
        //     133.23deg,
        //     #341f07 -18.74%,
        //     #c19c6f 6.69%,
        //     #341f07 53.53%
        //   );
        //   transition: all 0.15s ease;
        // }
        &:active {
          opacity: 70%;
        }
      }
      .typeActive {
        color: #ebddc4;
        background: linear-gradient(
          133.23deg,
          #341f07 -18.74%,
          #c19c6f 6.69%,
          #341f07 53.53%
        );
      }
    }
  }
  .containerFormContainer {
    display: flex;
    justify-content: center;
  }
  .containerFormInner {
    width: 740px;
    .inpBoxInner {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #a49077;
      background-color: #fdf1dc;
      font-family: "Sitka";
      font-size: 26px !important;
      font-weight: 400;
      text-align: left;
      border-radius: 10px;
      color: #3e280e;
      padding: 12px 28px;
      padding-right: 40px;
      height: 58px;

      @media (max-width: 1730px) {
        width: 382px;
        display: flex;
        justify-content: center;
        font-size: 22px !important;
      }
      @media (max-width: 930px) {
        width: 100%;
      }
      @media (max-width: 580px) {
        height: 44px;
        margin-bottom: 10px;
        padding-right: 40px !important;
      }

      &::-webkit-input-placeholder {
        color: #a49077;
      }

      &::-moz-placeholder {
        color: #a49077;
      }

      &:-ms-input-placeholder {
        color: #a49077;
      }

      &::-ms-input-placeholder {
        color: #a49077;
      }

      &::placeholder {
        color: #a49077;
      }
      outline: none;
      margin-bottom: 30px;
    }

    .error {
      border: 1px solid red;
      transition: all 0.15s ease;
    }

    .inpbox {
      position: relative;
      @media (max-width: 1730px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      input {
        @media (max-width: 580px) {
          padding: 12px 14px;
        }
      }
    }
    img {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      @include Hover;

      @media (max-width: 1730px) {
        width: 18px;
        height: 18px;
        right: 60px;
        top: 18px;
      }

      @media (max-width: 930px) {
        right: 15px;
      }
      @media (max-width: 580px) {
        right: 10px;
        top: 12px;
      }
    }

    button {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #a49077;
      background-color: #fdf1dc;
      font-family: "Sitka";
      font-size: 1.2vw;
      font-weight: 400;
      text-align: left;
      border-radius: 10px;
      color: #3e280e;
      padding: 1% 2%;
      margin-bottom: 3%;
      cursor: pointer;
      text-align: center;
      @include Hover;
      height: 58px;
      @media (max-width: 1730px) {
        width: 382px;
        margin: 0 auto;
      }
      @media (max-width: 930px) {
        width: 100%;
        margin-bottom: 10px;
      }
      @media (max-width: 580px) {
        height: 44px;
      }
    }
  }
  .add {
    display: flex;
    justify-content: center;

    button {
      font-size: 26px !important;
      @media (max-width: 1730px) {
        font-size: 22px !important;
      }
    }
  }
  .bottombox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-family: "Sitka";
      font-size: 26px;
      font-weight: 400;
      text-align: center;
      color: #3e280e;
      position: relative;
      top: -10px;
      @media (max-width: 530px) {
        top: -20px;
      }
      @media (max-width: 930px) {
        font-size: 18px;
      }
    }
    button {
      @include Button;
      font-size: 1.7vw;
      line-height: normal;
      padding: 20px 95px;
      text-align: center;
      margin: 0 auto;
      margin-top: 3%;
      position: relative;
      top: -15px;
      font-size: 38px;
      @media (max-width: 1730px) {
        padding: 10px 20px;
        font-size: 32px;
      }
      @media (max-width: 930px) {
        width: 100%;
      }
      @media (max-width: 930px) {
        font-size: 22px;
      }
    }
  }
}
