@import "./../../styles/mixins.scss";

.RightsPage {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  padding-top: 177px;
  @media (max-width: 1335px) {
    padding-top: 117px;
  }
  @media (max-width: 928px) {
    padding-top: 80px;
  }
  h1 {
    @include GradientTitle;
    width: 100%;
    text-align: center;
    font-family: SPSL-New-Cyrillic;
    font-size: 80px;
    font-weight: 400;
    line-height: 68.8px;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: 1520px) {
      font-size: 50px;
      line-height: 43px;
    }
    @media (max-width: 720px) {
      font-size: 26px;
      line-height: 22.36px;
    }
  }

  .container {
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    width: 1520px;
    justify-content: center;

    @media (max-width: 1520px) {
      width: 720px;
      flex-wrap: wrap;
    }

    @media (max-width: 780px) {
      flex-direction: column;
    }
    @media (max-width: 730px) {
      width: 100%;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 350px;
      margin: 60px;
      cursor: pointer;

      @media (max-width: 1520px) {
        width: 240px;
        margin: 0;
        margin: 20px 40px;
      }

      @media (max-width: 780px) {
        width: 100%;
        margin: 0;
        margin-bottom: 80px;
      }

      @media (max-width: 720px) {
        width: 100%;
        margin: 0;
        margin-bottom: 80px;
      }

      img {
        width: 200px;
        margin: 0 auto;
        @media (max-width: 780px) {
          width: 100px;
        }
      }
      p {
        //styleName: контент;
        font-family: Sitka;
        font-size: 32px;
        font-weight: 400;
        line-height: 40.04px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        color: #3e280e;
        text-wrap: nowrap;

        @media (max-width: 1520px) {
          font-size: 22px;
          line-height: 30.04px;
        }
        @media (max-width: 780px) {
          font-size: 26px;
          width: 50%;
          margin: 0 auto;
          margin-top: 20px;
        }

        @media (max-width: 720px) {
          font-size: 16px;
          line-height: 20.02px;
          width: 100%;
        }
      }
    }
  }
}
