@import "../../styles/mixins.scss";

.NewsCard {
  width: 1520px;
  display: flex;
  padding-bottom: 75px;

  @media (max-width: 1560px) {
    width: 720px;
    display: block;
  }
  @media (max-width: 980px) {
    padding-bottom: 25px;
  }
  @media (max-width: 720px) {
    padding-bottom: -15px;
  }
  @media (max-width: 760px) {
    width: 375px;
  }
  @media (max-width: 400px) {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  .NewsCardInfo {
    margin-left: 0px;
    position: relative;
    h2 {
      //   text-indent: 1em;
      margin-top: 0;
      font-size: 4vw;
      background: linear-gradient(
        89.52deg,
        #341f07 0.22%,
        #c39d71 13.52%,
        #341f07 32.61%,
        #c39d71 75.42%,
        #341f07 93.1%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .NewsCardSecond {
      position: absolute;
      bottom: 25px;
      width: 100%;
      @media (max-width: 1560px) {
        display: none;
      }
    }
  }
  .imgNews {
    width: 740px;
    height: 600px;
    margin-right: 40px;
    @media (max-width: 1560px) {
      display: none;
    }
    img {
      border-radius: 8px;
      width: 740px;
    }
  }
  .CardInfoBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 740px;

    @media (max-width: 1560px) {
      width: 720px;
    }
    @media (max-width: 760px) {
      width: 375px;
    }
    @media (max-width: 400px) {
      width: 100%;
    }

    .points {
      margin-top: 20px;
      padding-top: 0;
      margin-bottom: 0;
      transform: translateY(-20px);
    }

    p {
      color: #3e280e;
      font-weight: 400;
      text-align: left;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0;
      font-family: "Sitka";
      font-size: 26px;
      line-height: 32.53px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media (max-width: 1560px) {
        font-family: Sitka;
        font-size: 20px;
        font-weight: 400;
        line-height: 25.02px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      @media (max-width: 760px) {
        font-size: 16px;
        line-height: 20.02px;
      }
      a {
        color: #341f07;
        transition: 0.1s linear all;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
    .NewsCardSecondInner {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 1560px) {
        display: block;
      }
      p {
        font-family: SPSL-New-Cyrillic;
        font-size: 40px;
        font-weight: 400;
        line-height: 34.42px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: end;
        color: #000000;
      }
      a {
        text-decoration: none;
      }
      button {
        @include Button();
        padding: 8px 0px !important;
        width: 340px;
        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        @media (max-width: 1560px) {
          width: 100% !important;
          font-size: 30px;
          height: 50px;
          padding-top: 13px !important;
        }
        @media (max-width: 400px) {
          width: 100%;
        }
      }
    }
  }
  .EsimgNews {
    img {
      width: 740px;
      height: 600px;
      -o-object-fit: cover;
      object-fit: cover;
      border: 2px solid #341f07;
      border-radius: 10px;
      @media (max-width: 1560px) {
        width: 720px;
        height: 400px;
        box-sizing: border-box;
      }
      @media (max-width: 760px) {
        width: 100%;
        height: 200px;
      }
    }
  }
  .EsimgNewsPhone {
    display: none;
    @media (max-width: 1560px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.imgNewsPhone {
  position: relative;
  display: none;
  @media (max-width: 1560px) {
    display: block !important;
  }
  img {
    border: 2px solid #341f07;
    max-height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border: none;
  }
  margin: 15px 0px;
  @media (max-width: 720px) {
    img {
      width: 340px;
      height: 200px;
    }
  }
}
.NotimgNews {
  img {
    width: 740px;
    height: 600px;
    border: 2px solid #341f07;

    @media (max-width: 1560px) {
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 400px;
    }
    @media (max-width: 760px) {
      width: 100%;
      height: 200px;
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
  }
}
.EsimgNews {
  position: relative;
  // overflow: hidden; /* Ensures that the blurred background doesn't overflow */
  img {
    border: 2px solid #341f07;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  -webkit-filter: blur(10px);
  filter: blur(10px); /* Adjust the blur amount as needed */
  z-index: 1; /* Places the background behind the foreground image */
}

// .foregroundImage {
//   position: relative;
//   z-index: 2; /* Places the foreground image above the background */
//   max-width: 100%; /* Ensures the image scales properly */
//   height: auto; /* Maintains aspect ratio */

//   @media (max-width: 1560px) {
//     // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
//     width: 200px !important;
//     padding-left: 10px;
//   }
//   @media (max-width: 600px) {
//     padding: 0;
//   }
// }
.dateDesctope {
  @media (max-width: 1560px) {
    display: none !important;
  }
}
.dateMobile {
  display: none;
  @media (max-width: 1560px) {
    display: flex;
    width: 100%;
    justify-content: end;
    font-family: SPSL-New-Cyrillic !important;
    font-size: 30px !important;
    font-weight: 400;
    line-height: 25.82px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3e280e !important;
  }
  @media (max-width: 760px) {
    font-size: 20px !important;
    line-height: 17.21px !important;
  }
}
