.HomePage {
  background-image: url("./../../imgs/hramBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center -6vw;

  // .hramBg {
  //   position: absolute;
  //   width: 96vw;
  //   top: 2vw;
  //   left: 2vw;
  // }
}
