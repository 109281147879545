.MiniCalendarComponent {
  display: flex;
  flex-direction: row;
  height: 92px;
  transform: translateX(25px);

  @media (max-width: 1520px) {
    transform: none;
  }

  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vw;
    img {
      width: 1vw;
      height: 2vw;
      cursor: pointer;
    }
  }

  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 92px;
    flex-direction: column;
    margin: 8px;
    margin-top: 0;
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #fdf1dc;

    &:hover {
      border: 1px solid #c19c6f;
    }

    @media (max-width: 1520px) {
      margin: 17px 9px;
      height: 72px;
      width: 51px;
    }
  }

  .activeColumn {
    background-color: #3e280e;
    div {
      color: #fdf1dc;
    }
  }

  .selectedColumn {
    background-color: #e4d0ae;
    div:nth-child(1) {
      color: #bea881;
    }
    div {
      color: #3e280e;
    }
  }

  .day {
    display: flex;
    justify-content: center;
    color: #bea881;
    //styleName: новости контент;
    font-family: Sitka;
    font-size: 26px;
    font-weight: 400;
    line-height: 22.53px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    padding-bottom: 4px;

    @media (max-width: 1520px) {
      font-size: 20px;
      line-height: 15.02px;
    }
  }
  .date {
    color: #3e280e;

    font-family: Sitka;
    font-size: 44px;
    font-weight: 400;
    line-height: 43.05px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: 1520px) {
      font-size: 34px;
      line-height: 37.54px;
    }
  }
}
