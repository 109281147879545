@import "./../../styles/mixins.scss";

.StavrosPage {
  position: relative;
  padding-top: 114px;
  @media (max-width: 1520px) {
    transform: translateY(100px);
  }

  @media (max-width: $media1) {
    padding-top: 50px;
  }
  @media (max-width: 875px) {
    padding: 0;
    transform: none;
    // padding: 0 18px;
    padding-top: 0;
  }

  h2 {
    @include H2();
    line-height: 5vw;
    @include GradientTitle();

    font-family: SPSL-New-Cyrillic;
    font-size: 80px;
    font-weight: 400;
    line-height: 68.8px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    margin-top: 89px;
    transform: translateX(-2px);

    @media (max-width: 1520px) {
      font-size: 50px;
      line-height: 43px;
      margin-top: 0;
    }

    @media (max-width: 875px) {
      font-size: 26px;
      line-height: 22.36px;
      transform: translateY(96px);
    }

    @media (max-width: 365px) {
      font-size: 22px;
    }
  }
  .box {
    display: flex;
    justify-content: center;
    flex-direction: row;

    padding-top: 49px;

    @media (max-width: 1520px) {
      padding-top: 28px;
    }

    @media (max-width: 875px) {
      flex-direction: column;
      padding: 0 18px;
      margin-top: 115px;
    }
  }

  .left {
    display: flex;

    @media (max-width: 1520px) {
      width: 370px;
    }

    @media (max-width: 875px) {
      width: 100%;
      justify-content: center;
    }
    img {
      width: 610px;
      height: 425px;
      margin-right: 168px;

      @media (max-width: 1520px) {
        width: 354px;
        height: 246.64px;
        margin: 0;
        padding: 0;
        padding-left: 2px;
      }
      @media (max-width: 875px) {
        max-width: 339px;
        width: 100%;

        height: auto;
        padding: 0;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .rigth {
    padding-right: 48px;
    position: relative;
    transform: translateX(-20px);
    @media (max-width: 1520px) {
      padding-right: 0;
      padding-left: 17px;
      width: 340px;
      br {
        display: none;
      }
    }

    @media (max-width: 875px) {
      margin: 0 auto;
      max-width: 339px;
      width: 100%;

      padding: 0;
      transform: none;
      margin-top: 18px;
    }

    .lepest2 {
      display: none;

      @media (max-width: 1520px) {
        display: flex;
        position: absolute;
        left: 111px;
        top: 94px;
      }
      @media (max-width: 875px) {
        display: none;
      }
    }

    .lepest {
      display: flex;
      position: absolute;
      left: -115px;
      top: 40px;
      width: 232px;

      @media (max-width: 1520px) {
        display: none;
      }
    }
    p:nth-child(1) {
      margin-bottom: 194px;
      margin-top: -19px;

      @media (max-width: 1520px) {
        margin-bottom: 0;
        margin-top: 1px;
      }
    }
    p {
      position: relative;
      color: #3e280e;

      font-family: Sitka;
      font-size: 32px;
      font-weight: 400;
      line-height: 40.04px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      @media (max-width: 1520px) {
        font-size: 18px;
        line-height: 22.52px;
      }

      @media (max-width: 875px) {
        font-family: SPSL-New-Cyrillic;
        font-size: 16px;
        font-weight: 400;
        line-height: 13.76px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
      }
    }
    p:nth-child(2) {
      padding-top: 32px;

      @media (max-width: 1520px) {
        padding-top: 18px;
        transform: translateY(11px);
      }

      @media (max-width: 875px) {
        padding-top: 0px;
        transform: translateY(-3px);
      }
    }
    p:nth-child(3) {
      padding-top: 21px;

      @media (max-width: 1520px) {
        padding: 0;
      }
    }
    span {
      color: #3e280e;
      font-family: "SPSL-New-Cyrillic";
      line-height: 0;

      font-size: 80px;
      font-weight: 400;
      line-height: 68.8px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      @media (max-width: 1520px) {
        font-size: 50px;
        line-height: 40.8px;
      }

      @media (max-width: 875px) {
        font-family: Sitka;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.02px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
      }
    }
  }

  .imgasPlanshet {
    display: none;
    padding: 0 40px;

    @media (max-width: 1520px) {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      justify-content: center;
      margin-top: 57px;
      img {
        width: 475px;
        height: 295px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 10px;
      }

      img:nth-child(2) {
        margin: 0 12px;
      }
    }
    @media (max-width: 1309px) {
      img {
        width: 375px;
        height: 195px;
      }
    }

    @media (max-width: 1309px) {
      img {
        width: 375px;
        height: 195px;
      }
    }

    @media (max-width: 875px) {
      display: flex;
      justify-content: left;
      overflow-x: auto;
      width: auto;
      padding: 0 18px;

      margin: 0;
      img {
        width: 338px;
        height: 238px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: 400px) {
      img {
        width: 90%;
        height: auto;
      }
    }
  }
  .slider {
    display: flex;
    justify-content: center;
    color: #3e280e;
    height: auto;
    margin: 93px 0;
    align-items: center;
    flex-direction: column;
    margin-bottom: 229px;

    @media (max-width: 1520px) {
      display: none;
    }

    // .sliderPoints {
    //   margin-top: 1vw;
    //   display: flex;
    //   flex-direction: row;
    //   .point {
    //     width: 0.6vw;
    //     height: 0.6vw;
    //     background-color: #3e280e;
    //     margin: 0 0.2vw;
    //     border-radius: 50%;
    //   }
    // }
  }
  .boxInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 93px;

    @media (max-width: 1520px) {
      position: relative;
      width: 780px;
      margin: 0 auto;
      margin-bottom: 377px;
    }

    @media (max-width: 875px) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      padding: 0 18px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: end;
      color: #3e280e;

      @media (max-width: 875px) {
        margin: 0 auto;
      }
      h3 {
        font-family: SPSL-New-Cyrillic;
        font-size: 40px;
        font-weight: 400;
        line-height: 34.42px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;

        @media (max-width: 1520px) {
          font-size: 32px;
          line-height: 38.54px;
          text-align: left;
        }

        @media (max-width: 875px) {
          font-family: SPSL-New-Cyrillic;
          font-size: 22px;
          font-weight: 400;
          line-height: 22.36px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
        }
      }
      p {
        font-family: Sitka;
        font-size: 20px;
        font-weight: 400;
        line-height: 25.02px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;

        @media (max-width: 1520px) {
          font-size: 16px;
          line-height: 20.02px;
          text-align: left;
        }

        @media (max-width: 875px) {
          font-family: Sitka;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.02px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
        }
      }
      .phone {
        font-family: Sitka;
        font-size: 40px;
        font-weight: 400;
        line-height: 50.04px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;

        @media (max-width: 1520px) {
          font-size: 28px;
          line-height: 35.03px;
          text-align: left;
          transform: translateY(-20px);
        }
        @media (max-width: 875px) {
          font-size: 16px;
          line-height: 20.02px;
        }
      }
    }
    .info:nth-child(1) {
      width: 480px;

      @media (max-width: 1520px) {
        width: 298px;
        margin-right: 27px;
        padding-left: 20px;
      }

      @media (max-width: 875px) {
        margin: 0 auto;
        padding: 0;
        max-width: 339px;
        width: 100%;
      }

      p {
        padding: 0;
        margin: 0;
      }
      p:nth-child(2) {
        transform: translateY(-32px);

        @media (max-width: 875px) {
          transform: translateY(-25px);
        }
      }
    }

    .info:nth-child(2) {
      margin: 0 41px;
      width: 477px;

      @media (max-width: 1520px) {
        width: 333px;
        transform: translateY(-19px);
      }
      @media (max-width: 875px) {
        margin: 0 auto;
        padding: 0;
        max-width: 339px;
        width: 100%;
      }
      p {
        padding: 0;
        margin: 0;
      }
      p:nth-child(2) {
        transform: translateY(-32px);
        @media (max-width: 875px) {
          transform: translateY(-25px);
        }
      }
    }

    .infoEnd {
      width: 480px;
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
      color: #3e280e;

      @media (max-width: 1520px) {
        position: absolute;
        bottom: -57px;
        left: -87px;
      }

      @media (max-width: 875px) {
        margin: 0 auto;
        padding: 0;
        max-width: 339px;
        width: 100%;

        position: relative;
        left: auto;
        bottom: auto;
        text-align: left;
        justify-content: left;
        transform: translateY(-20px);
      }

      a {
        text-decoration: none;
        outline: none;
        color: #3e280e;
        display: flex;
        flex-direction: row;
      }
      p {
        cursor: pointer;
        font-family: Sitka;
        font-size: 26px;
        font-weight: 400;
        line-height: 3.53px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        transform: translateX(9px);

        @media (max-width: 1520px) {
          font-size: 22px;
          line-height: 2.52px;
          text-align: left;
        }
        @media (max-width: 875px) {
          font-size: 20px;
        }
      }

      img {
        transform: translateX(2px) translateY(2px);
        width: 52px;
        height: 52px;
        margin-right: 9px;
        cursor: pointer;
        @include Hover;

        @media (max-width: 1520px) {
          width: 42px;
          height: 42px;
          transform: translateX(0px) translateY(2px);
        }
        @media (max-width: 875px) {
          margin-right: 4px;
        }
      }
    }
  }
  .uzor {
    display: none;
    position: absolute;
    left: 0;
    bottom: -474px;
    z-index: 300;
  }
}
