@import "./../../styles/mixins.scss";

.PardonPage {
  margin-bottom: 10vw;
  padding-top: 114px;
  position: relative;

  @media (max-width: 1520px) {
    padding-top: 80px;
  }
  @media (max-width: 928px) {
    padding-top: 40px;
  }

  .container {
    // @include GradientSpan;
    h2 {
      @include H2;
      @include GradientTitle;
      @media (max-width: 930px) {
        font-size: 26px;
      }
    }

    .box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media (max-width: 930px) {
        display: block;
      }
    }

    .listPhone {
      display: none;
      flex-direction: column;
      width: auto;
      margin-top: 100px;

      @media (max-width: 1730px) {
        margin-top: 30px;
      }
      @media (max-width: 930px) {
        display: flex;
        width: 100%;
        padding-left: calc((100% - 420px) / 2);
        padding-right: calc((100% - 420px) / 2);
        box-sizing: border-box;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;
        &::-webkit-scrollbar-thumb {
          background: #3e280e; /* Цвет ползунка при наведении */
          border-radius: 10px; /* Закругление углов трека */
        }
        &::-webkit-scrollbar {
          height: 4px; /* Высота горизонтального скролл-бара */
          border-radius: 10px; /* Закругление углов трека */
          display: none;
          @media (max-width: 580px) {
            display: block;
          }
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px; /* Закругление углов трека */
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #7b5c3b; /* Цвет ползунка при наведении */
          border-radius: 10px; /* Закругление углов трека */
        }
      }
      @media (max-width: 580px) {
        width: 100%;
      }
      @media (max-width: 480px) {
        width: 100%;
        padding: 0 10px;
      }

      .listPhoneItem {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
      p {
        font-family: "SPSL-New-Cyrillic";
        font-weight: 400;
        text-align: left;
        padding: 0;
        cursor: pointer;
        padding: 15px 44px;
        color: #3e280e;
        text-wrap: nowrap;
        transition: 0.1s linear all;
        @media (max-width: 1730px) {
          margin: 30px 10px 20px 0px;
          padding: 0px;
        }
        span {
          border-radius: 10px;
          padding: 11px;
          font-size: 60px;
          @media (max-width: 1730px) {
            padding: 11px;
            font-size: 28px;
            color: inherit;
          }
          @media (max-width: 580px) {
            font-size: 24px;
          }
        }
      }
      p:last-child {
        @media (max-width: 930px) {
          padding-right: 0;
          margin-right: 0;
        }
      }
      // span:hover {
      //   color: #fdf1dc;
      //   background-color: #3e280e;
      // }
      .pActive {
        color: #fdf1dc;
        background-color: #3e280e;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      width: auto;
      margin-top: 100px;
      @media (max-width: 1730px) {
        margin-top: 30px;
        @media (max-width: 930px) {
          display: none;
        }
      }
      p {
        font-family: "SPSL-New-Cyrillic";
        font-weight: 400;
        text-align: left;
        padding: 0;
        cursor: pointer;
        padding: 15px 44px;
        color: #3e280e;
        text-wrap: nowrap;
        transition: 0.1s linear all;
        @media (max-width: 1730px) {
          margin: 30px 10px 20px 0px;
          padding: 0px;
        }

        span {
          border-radius: 10px;
          padding: 11px;
          font-size: 60px;
          @media (max-width: 1730px) {
            padding: 11px;
            font-size: 28px;
            color: inherit;
          }
        }
      }
      span:hover {
        color: #fdf1dc;
        background-color: #3e280e;
      }
      .pActive {
        color: #fdf1dc;
        background-color: #3e280e;
      }
    }
  }
  .pardonImg {
    position: absolute;
    left: 0;
    @media (max-width: 1730px) {
      display: none;
    }
  }
}
