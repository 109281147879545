@import "../../../styles/mixins.scss";

.HomeModuleTitle {
  display: flex;
  justify-content: center;
  padding-top: 114px;

  @media (max-width: $media1) {
    padding-top: 0;
  }
  .containerMain {
    display: flex;
    flex-direction: row;
    padding-top: 207px;
    @media (max-width: $media1) {
      padding-top: 156px;
    }

    img:nth-child(2) {
      margin-top: 100px;
      width: 60%;
    }
  }
  .Left {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;

    @media (max-width: $media1) {
      width: 501px;
    }

    @media (max-width: $media2) {
      display: none;
    }

    @media (max-width: 1083px) {
      display: none;
    }

    img {
      transform: translateY(-58px);
      @media (max-width: $media1) {
        -o-object-fit: fill;
        object-fit: fill;
        width: 516px;
        height: 461px;
        transform: translateY(-21px) translateX(-18px);
      }
    }
  }
  .Right {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-bottom: 328px;
    margin: 0 auto;
    padding-left: 359px;

    @media (max-width: $media1) {
      margin: 0 auto;
      margin-bottom: 0;
      padding-left: 98px;
      padding-bottom: 225px;
    }

    @media (max-width: $media2) {
      margin: 0 auto;
      padding: 0;
      // padding-left: 33px;
      padding-top: 74px;
      // padding-right: 31px;
    }

    .title {
      position: relative;
      width: 100%;
      img {
        width: 114.12px;
        height: 121.32px;
        position: absolute;
        @media (max-width: $media1) {
          transform: scale(-1, 1);
        }

        @media (max-width: 1184px) {
          display: none;
        }
        @media (max-width: $media2) {
          display: flex;
        }
      }
      img:nth-child(1) {
        width: 90.12px;
        height: 90.32px;
        left: -123px;
        top: -51px;
        transform: rotate(-14deg);
        @media (max-width: $media1) {
          transform: rotate(1deg) scale(-1, 1);
          width: 99.14px;
          height: 94.52px;
          top: -35px;
          left: 662px;
        }
        @media (max-width: $media2) {
          left: 145px;
          top: -116px;
        }
      }
      img:nth-child(2) {
        top: -253px;
        left: -20px;
        @media (max-width: $media1) {
          width: 72.79px;
          height: 74.61px;
          top: -53px;
          transform: rotate(10deg) scale(-1, 1);
          left: 772px;
        }

        @media (max-width: $media2) {
          left: 239px;
          top: -147px;
          transform: rotate(347deg) scale(-1, 1);
        }

        @media (max-width: 335px) {
          left: 229px;
        }
      }
      h2 {
        font-size: 156px;
        font-weight: 400;
        line-height: 134.16px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        font-family: "SPSL-New-Cyrillic";

        background: linear-gradient(
          89.52deg,
          #341f07 0.22%,
          #c39d71 13.52%,
          #341f07 32.61%,
          #c39d71 75.42%,
          #341f07 93.1%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-wrap: nowrap;
        margin-bottom: 151px;
        margin-top: 0;
        padding-top: 0;
        margin-left: 37px;

        @media (max-width: $media1) {
          font-size: 80px;
          font-weight: 400;
          line-height: 68.8px;
          margin-left: 36px;
          margin-bottom: 78px;
        }

        @media (max-width: $media2) {
          font-size: 80px;
          font-weight: 400;
          line-height: 68.8px;
          text-align: left;
          width: 247px;
          text-wrap: wrap;
          margin: 0;
          padding: 0;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: end;
      position: relative;
      width: auto;
      margin-right: 6px;
      @media (max-width: $media1) {
        margin-right: 0px;
      }

      @media (max-width: $media2) {
        margin: 0;
        padding: 0;
        width: 307px;
      }
      button {
        @include Button();
        width: 870px;
        text-wrap: nowrap;
        font-family: "SPSL-New-Cyrillic";
        font-size: 80px;
        font-weight: 400;
        line-height: 68.8px;
        letter-spacing: -0.01em;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        padding-left: 34px;
        @media (max-width: $media1) {
          width: 415px;
          font-size: 30px;
          line-height: 25.8px;
          letter-spacing: -0.01em;
          text-align: center;
          padding: 31px 5px;
          padding-left: 23px;
        }

        @media (max-width: $media2) {
          font-size: 24px;
          font-weight: 400;
          line-height: 1.64px;
          letter-spacing: -0.01em;
          height: 21px;
          padding: 28px 30px;
          margin: 0;
          margin-top: 32px;
          width: auto;
          z-index: 20;
        }
      }
      img {
        height: 100%;
        @media (max-width: $media2) {
          display: none;
        }
      }
      .imgtop {
        position: absolute;
        top: -147px;
        right: 15px;
        width: 59px;
        height: 186px;
        transform: scaleY(-1) rotate(-113deg);
        @media (max-width: $media1) {
          top: -149px;
          right: 30px;
          width: 38px;
          height: 210px;
        }
      }
      .imgleft {
        position: absolute;
        left: -91px;
        height: 188px;
        @media (max-width: $media1) {
          left: -48px;
          height: 99px;
        }
      }
      .imgbottom {
        position: absolute;
        bottom: -140px;
        left: 55px;
        transform: scaleY(-1) rotate(90deg);
        width: 60px;
        height: 184px;
        @media (max-width: $media1) {
          bottom: -121px;
          left: 31px;
          width: 33px;
          height: 184px;
        }
      }
      .imgUzor2 {
        position: absolute;
        bottom: -129px;
        left: -157px;

        width: 154.41px;
        height: 146.61px;

        @media (max-width: $media1) {
          bottom: -75px;
          left: -88px;
          width: 95.41px;
          height: 81.61px;
        }
      }
    }
  }
}
