@import "../../styles/mixins.scss";

.NotFound {
  .container {
    background-image: url("./../../imgs/bgNotFound.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 10px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .containerInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 286px;
    h1 {
      font-family: "SPSL-New-Cyrillic";
      font-size: 60px;
      font-weight: 400;
      line-height: 51.64px;
      text-align: center;
      color: #3e280e;

      @media (max-width: 1000px) {
        font-size: 50px;
        line-height: 43px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
      }
    }
    button {
      @include Button;
      width: 480px;
      height: 80px;
      font-family: "SPSL-New-Cyrillic";
      font-size: 40px;
      font-weight: 400;
      line-height: 34.42px;
      text-align: center;
      padding: 0;

      @media (max-width: 1000px) {
        font-size: 30px;
        line-height: 43px;
        width: 280px;
        height: 60px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
        width: 200px;
        height: 40px;
      }
    }
  }
}
