@import "./../../../styles/mixins.scss";
.HomeModuleAdress {
  position: relative;
  margin: 0 auto;
  margin-top: 42px;

  @media (max-width: $media2) {
    margin-top: 26px;
  }
  .HomeModuleAdress_inner {
    width: 1390px;

    display: flex;
    padding-left: 138px;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 216px;

    @media (max-width: $media1) {
      width: 750px;
      padding-left: 0;
      margin-bottom: 98px;
    }

    @media (max-width: $media2) {
      max-width: 375px;
      width: 100%;
      margin-bottom: 32px;
    }
  }
  h2 {
    @include H2();
    text-align: left;
    margin-bottom: 212px;
    font-family: SPSL-New-Cyrillic;
    font-size: 80px;
    font-weight: 400;
    line-height: 68.8px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: $media1) {
      font-size: 50px;
      font-weight: 400;
      line-height: 43px;
      padding: 0;
      margin: 0;
      margin-bottom: 50px;
      text-align: center;
      padding-top: 15px;
    }

    @media (max-width: $media2) {
      font-size: 24px;
      line-height: 20.64px;
      font-size: 24px;
      font-weight: 400;
      line-height: 20.64px;
      margin-bottom: 20px;
    }
  }
  .containerFlex {
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media (max-width: $media1) {
      flex-direction: column;
    }
    .left {
      display: flex;
      flex-direction: column;
      margin-right: 195px;

      @media (max-width: $media1) {
        margin-right: 0;
        padding-left: 15px;
      }

      @media (max-width: $media2) {
        padding-left: 18px;
      }
    }
    .right {
      border: 4px solid #341f07;
      border-radius: 10px;
      width: 730px;
      height: 466px;
      margin-right: 10px;

      iframe {
        border-radius: 10px;
      }

      @media (max-width: $media1) {
        width: 882px;
        height: 443px;
        transform: translateX(-70px);
      }
      @media (max-width: $media2) {
        width: 97%;
        height: 248px;
        transform: none;
        margin-right: 0;
        margin: 0 auto;
        border: 2px solid #341f07;
      }

      .map {
        width: 730px;
        height: 466px;

        -o-object-fit: cover;

        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        @media (max-width: $media1) {
          width: 882px;
          height: 443px;
        }

        @media (max-width: $media2) {
          width: 100%;
          height: 248px;
          border-radius: 10px;
        }
      }
    }
    h3 {
      @include H3;
      margin: 0;
      padding-top: 0;
      text-wrap: nowrap;
      font-size: 60px;
      font-weight: 400;
      line-height: 51.64px;

      @media (max-width: $media1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 25.82px;
      }

      @media (max-width: $media2) {
        font-size: 22px;
        line-height: 18.93px;
      }
    }
    p {
      @include P;
      font-family: Sitka;
      font-size: 32px;
      font-weight: 400;
      line-height: 40.04px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      margin-top: 40px;
      letter-spacing: 0.17px;
      padding-bottom: 0;
      margin-bottom: 4px;

      @media (max-width: $media1) {
        font-size: 20px;
        font-weight: 400;
        line-height: 25.02px;
        display: inline;
        margin: 0;
        // margin-bottom: 13px;
        br {
          display: none;
        }
      }
      @media (max-width: $media2) {
        font-size: 16px;
        line-height: 20.02px;
        text-wrap: wrap;
        margin-top: 4px;
        width: 246px;
      }
    }
    .email {
      display: flex;
      justify-content: left;
      cursor: pointer;
      margin-top: 0;

      &:hover {
        text-decoration: underline;
      }
      @media (max-width: $media1) {
        // margin-top: 20px;
        margin-bottom: 15px;
      }
      @media (max-width: $media2) {
        margin-top: 0;
      }
    }
  }
  .uzor3 {
    position: absolute;
    bottom: -387px;
    z-index: 100;
    left: 0;
    display: none;
    @media (max-width: $media1) {
      width: 185.32px;
      height: 312.02px;
      bottom: -236px;
      display: flex;
    }

    @media (max-width: $media2) {
      display: none;
    }
    @media (max-width: 1227px) {
      display: none;
    }
  }
}
