.StavrosSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82.5vw;
  position: relative;

  .slide {
    width: 40vw;
    height: 30vw;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .prevButton {
    position: absolute;
    z-index: 5;
    left: 22%;
    top: 45%;
    width: 1.5vw;
  }
  .nextButton {
    position: absolute;
    z-index: 5;
    right: 22%;
    top: 45%;
    width: 1.5vw;
  }
  .customNavigation {
    img {
      transition: 0.1s linear all;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
