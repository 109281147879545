@import "../../styles/mixins.scss";

.Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ceb8924d;
  height: 114px;
  position: fixed;
  z-index: 200;

  @media (max-width: 1335px) {
    background: none;
    height: auto;
  }

  header {
    width: 100%;
    .headerInner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background-color: #efdfc6;
      // }
      .logoHome {
        cursor: pointer;
        img {
          width: 114px;
          height: 105px;
          margin-left: 11px;
          margin-top: 10px;
          margin-right: 0;
          padding-right: 0;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
            transition: all 0.15s ease;
          }
          @media (max-width: 1335px) {
            width: 80.34px;
            height: 74px;
          }
          @media (max-width: $media2) {
            width: 52.11px;
            height: 48px;
            margin-left: 10px;
            margin-top: 13px;
            transform: translateY(-4px);
          }
        }
      }
    }
  }

  .burger {
    display: none;
    cursor: pointer;
    @media (max-width: 1335px) {
      display: flex;
      position: absolute;
      top: 32px;
      right: 134px;
    }

    @media (max-width: $media2) {
      width: 40px;
      height: 23px;
      top: 20px;
      right: 18px;
    }

    &:hover {
      opacity: 0.7;
      transition: all 0.15s ease;
    }
  }

  .showMenu {
    display: none;

    .minitample {
      display: none;
    }

    @media (max-width: 1335px) {
      display: flex;
      background-color: rgba(206, 184, 146, 0.3);
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 379px;
      z-index: 100;
    }

    @media (max-width: $media2) {
      height: auto;
      width: 100vw;
      display: flex;
      justify-content: end;
      padding-top: 89px;
      left: 0;
      // top: 61.43px;
      position: absolute;
      z-index: 100;
    }

    .showMenuInner {
      @media (max-width: 1335px) {
        position: absolute;
        right: 200px;
        margin-top: 40px;

        p {
          font-family: SPSL-New-Cyrillic;
          font-size: 20px;
          font-weight: 400;
          line-height: 17.2px;
          text-align: center;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
          text-align: right;
          padding-bottom: 8px;

          @media (max-width: $media2) {
            font-size: 24px;
            font-weight: 400;
            line-height: 20.64px;
            padding-right: 20px;
          }
        }
        p:nth-child(1) {
          padding-top: 0;
          margin-top: 0;
        }
        a {
          outline: none;
          text-decoration: none;
          color: rgba(52, 31, 7, 1);
          border-radius: 5px;
          padding: 9px 16px;
          &:hover {
            background-color: rgba(227, 206, 175, 1);
            transition: all 0.15s ease;
          }
        }
      }

      @media (max-width: $media2) {
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        margin: 0;
      }
    }

    .logo {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        transition: all 0.15s ease;
      }
      @media (max-width: 1335px) {
        width: 80.34px;
        height: 74px;
        margin-left: 11px;
        margin-top: 10px;
      }

      @media (max-width: $media2) {
        position: absolute;
        width: 52.11px;
        height: 48px;
        left: -1px;
        top: -2px;
      }
    }

    .close {
      position: absolute;
      right: 134px;
      top: 17px;
      padding: 10px;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: rgba(227, 206, 175, 1);

        opacity: 0.7;
        transition: all 0.15s ease;
      }

      @media (max-width: $media2) {
        top: 13px;
        right: 17px;
      }
    }
  }

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 28px;
    font-weight: 400;
    line-height: 24.08px;
    text-align: left;
    text-underline-position: from-font;

    -webkit-text-decoration-skip-ink: none;

    text-decoration-skip-ink: none;

    font-family: "SPSL-New-Cyrillic", arial;
    margin-right: 150px;
    text-wrap: nowrap;

    @media (max-width: 1500px) {
      font-size: 24px;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: #000;
      margin: 0 25px;
      padding: 4px 0;
      &:hover {
        opacity: 0.7;
        transition: all 0.15s ease;
      }
      @media (max-width: 1650px) {
        margin: 0 12px;
      }
      li {
        color: #000;
      }
      @media (max-width: 1335px) {
        display: none;
      }
    }
    li:nth-child(1) {
      margin-left: -13px;
    }
  }
  .headerDisign {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;

    @media (max-width: 1730px) {
      width: 139.94px;
      height: 136.88px;
    }
    @media (max-width: 1530px) {
      width: 109.94px;
      height: 106.88px;
      top: 0;
      right: 0px;
      overflow: hidden;
    }

    @media (max-width: $media2) {
      display: none;
    }
  }
}
