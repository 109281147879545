.calendarModal .show .react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  font-weight: 400;
  color: #341f07;
  height: 48px;
}

.calendarModal .react-calendar__month-view__weekdays {
  font-size: 14px;
  height: 60px;
  display: flex;
  align-items: center;
}

.show .react-calendar__navigation__label {
  font-size: 14px;
  color: #341f07;
}
.show .react-calendar__navigation {
  margin-bottom: 10px;
}
.calendarModal .react-calendar__navigation button {
  width: 48px;
  min-width: auto;
  font-size: 16px;
  padding: 0;
}

/* Фикс для сафари */
.react-calendar button {
  color: #341f07 !important;
}

.react-calendar button abbr {
  color: #341f07;
}

.react-calendar__tile--active abbr {
  color: #ffffff !important;
}

.calendarModal .react-calendar__tile {
  border-radius: 50%;
  padding: 6px 0;
}
.calendarModal .react-calendar__tile--active {
  background-color: #341f07 !important;
}
.calendarModal .react-calendar__tile {
  font-size: 16px;
}
.calendarModal .react-calendar__navigation__label__labelText {
  color: #341f07;
}

.calendarModal .react-calendar {
  padding: 10px;
  background-color: #fff8ec;
  border-radius: 10px;
  border: 2px solid;
  background: linear-gradient(
    89.52deg,
    #341f07 0.22%,
    #c39d71 13.52%,
    #341f07 32.61%,
    #c39d71 75.42%,
    #341f07 93.1%
  );
  -webkit-background-clip: text;
  background-clip: text;
  height: auto;
  min-height: 500px;

  box-sizing: border-box;
  width: 360px;
}

@media (max-width: 364px) {
  .calendarModal .react-calendar {
    width: 100%;
  }
}

.calendarModal .react-calendar__tile--now {
  background-color: #ffffff;
}

.calendarModal .react-calendar__month-view__days {
  line-height: 38px;
}
.calendarModal .react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
  color: #bea881;
  abbr {
    outline: none;
    text-decoration: none;
  }
}
.calendarModal .calendar2 {
  position: relative;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 364px) {
  .calendarModal {
    width: 95%;
    margin: 0 auto;
  }
}

.calendarModal {
  position: absolute;
  top: 92px;
  background-color: #fff8ec;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
}

@media (max-width: 750px) {
  .calendarModal {
    display: none;
    position: relative;
    height: auto;
    min-height: 500px;
    opacity: 1;
    top: auto;
    width: 356px;
    margin: 0 auto;
  }
}

@media (max-width: 380px) {
  .calendarModal {
    position: relative;
    height: auto;
    min-height: 500px;
    opacity: 1;
    top: auto;
    width: 100%;
    margin: 0 auto;
  }
}
.calendarModal.show {
  height: auto;
  min-height: 500px;
  opacity: 1;
}
@media (max-width: 750px) {
  .calendarModal.show {
    display: flex;
  }
}
.calendarTextPhone {
  display: none;
}

@media (max-width: 750px) {
  .calendarTextPhone {
    width: 100%;
    background-color: #fdf1dc;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: SPSL-New-Cyrillic;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 50px;
    justify-content: center;
  }
  .calendarTextPhone img {
    width: 12px;
    transition: all 0.3s ease;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
  }
  .calendarTextPhone p {
    border: 1px solid #341f07;
    padding: 7px 15px;
    border-radius: 5px;
  }
}

.calendarText {
  background-color: #fdf1dc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: SPSL-New-Cyrillic;
  font-size: 60px;
  font-weight: 400;
  line-height: 51.64px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
@media (max-width: 1520px) {
  .calendarText {
    font-size: 40px;
    line-height: 34.42px;
    transform: translateX(-80px);
  }
}

@media (max-width: 750px) {
  .calendarText {
    display: none;
  }
}
.calendarText p {
  padding-top: 10px;
  padding-left: 0;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #fdf1dc;
  transform: translateY(6px);
  outline: none;
}

.calendarText img {
  padding: 4px 8px;
  width: 23px;
  transition: all 0.3s ease;
}

.ok {
  position: absolute;
  bottom: 5px;
  right: 20px;
  font-size: 16px;
  font-family: "Sitka";
  cursor: pointer;
  padding: 4px;
}
/* @media (max-width: 750px) {
  .ok {
    display: none;
  }
} */

.ok:hover {
  background-color: rgba(239, 239, 239, 0.067);
}
.calendarModal .react-calendar__year-view .react-calendar__tile,
.calendarModal .react-calendar__century-view .react-calendar__tile,
.calendarModal .react-calendar__decade-view .react-calendar__tile {
  padding: 15px 5px !important;
}
