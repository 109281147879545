$media1: 1730px;
$media2: 928px;

@mixin Button() {
  background: linear-gradient(
    234.27deg,
    #ebddc4 22.46%,
    #f9f5ee 50.28%,
    #ebddc4 78.1%
  );
  padding: 50px 40px;
  font-family: "SPSL-New-Cyrillic";
  font-size: 80px;
  font-weight: 400;
  line-height: 68.8px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #3e280e;
  box-shadow: 3px 3px 3px 0px #402a10;
  border: 1px solid #402a1033;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease;
  &:hover {
    color: #ebddc4;
    background: linear-gradient(
      133.23deg,
      #341f07 -18.74%,
      #c19c6f 6.69%,
      #341f07 53.53%
    );
    transition: all 0.15s ease;
  }
  &:active {
    opacity: 70%;
  }
}

@mixin H2() {
  font-family: "SPSL-New-Cyrillic";
  font-size: 4vw;
  font-weight: 400;
  line-height: 86px;
  text-align: center;
  color: #3e280e;
}

@mixin H3() {
  font-family: "SPSL-New-Cyrillic";
  font-size: 3vw;
  font-weight: 400;
  line-height: 86px;
  text-align: left;
  color: #3e280e;
}

@mixin P() {
  font-family: "Sitka";
  font-size: 2.5vw;
  font-weight: 400;
  // line-height: 40.04px;
  line-height: 2vw;
  text-align: left;
  color: #3e280e;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin GradientText() {
  background: linear-gradient(
    92.01deg,
    #c39d71 3.09%,
    #fdf1dc 18.46%,
    #c39d71 81.9%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin GradientTitle() {
  background: linear-gradient(
    89.52deg,
    #341f07 0.22%,
    #c39d71 13.52%,
    #341f07 32.61%,
    #c39d71 75.42%,
    #341f07 93.1%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin GradientSpan() {
  background: linear-gradient(
    89.52deg,
    #341f07 0.22%,
    #c39d71 13.52%,
    #341f07 32.61%,
    #c39d71 75.42%,
    #341f07 93.1%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@mixin GradientBorder() {
  -webkit-border-image: linear-gradient(
      135deg,
      #341f07 0%,
      #c19c6f 43.5%,
      #341f07 100%
    )
    1;
  -moz-border-image: linear-gradient(
      135deg,
      #341f07 0%,
      #c19c6f 43.5%,
      #341f07 100%
    )
    1;
  -o-border-image: linear-gradient(
      135deg,
      #341f07 0%,
      #c19c6f 43.5%,
      #341f07 100%
    )
    1;
  border-image: linear-gradient(135deg, #341f07 0%, #c19c6f 43.5%, #341f07 100%)
    1;
  border-width: 4px;
  border-style: solid;
}

@mixin Hover() {
  &:hover {
    opacity: 0.7;
    transition: all 0.15s ease;
  }
  &:active {
    opacity: 0.8;
    transition: all 0.15s ease;
  }
}
