@import "./../../styles/mixins.scss";

.AboutComingPage {
  padding-bottom: 283px;
  position: relative;
  min-height: 75vh;

  padding-top: 114px;
  @media (max-width: $media1) {
    padding-top: 50px;
  }

  @media (max-width: 1680px) {
    padding-bottom: 147px;
  }

  @media (max-width: 553px) {
    padding-bottom: 101px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1680px) {
      padding-top: 59px;
    }

    h2 {
      @include H2;
      @include GradientTitle;
      //styleName: заголовок;
      font-family: SPSL-New-Cyrillic;
      font-size: 80px;
      font-weight: 400;
      line-height: 112.8px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      @media (max-width: 1680px) {
        font-family: SPSL-New-Cyrillic;
        font-size: 50px;
        font-weight: 400;
        line-height: 43px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
      }

      @media (max-width: 553px) {
        font-family: SPSL-New-Cyrillic;
        font-size: 26px;
        font-weight: 400;
        line-height: 50.36px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
      }
    }

    .box {
      display: flex;
      justify-content: center;
      flex-direction: row;

      @media (max-width: 1680px) {
        flex-direction: column;
      }
    }

    .plitka {
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: auto;

      @media (max-width: 1680px) {
        justify-content: center;
      }

      a {
        outline: none;
        text-decoration: none;
      }
      button {
        @include Button();
        font-family: SPSL-New-Cyrillic;
        font-size: 40px;
        font-weight: 400;
        line-height: 34.42px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        padding: 20px 90px;

        @media (max-width: 1680px) {
          font-size: 32px;
          line-height: 18.54px;
          width: 476px;
        }

        @media (max-width: 553px) {
          width: 339px;
          padding: 13px 37px 10px 52px;
          font-size: 22px;
          line-height: 18.93px;
        }

        @media (max-width: 350px) {
          width: auto;
          padding: 13px 57px 10px 72px;
          margin: 0 auto;
        }
      }

      .Bottom {
        display: flex;
        flex-direction: column;
      }

      p {
        margin: 0;
        padding: 0;
        font-family: Sitka;
        font-size: 32px;
        font-weight: 400;
        line-height: 40.04px;
        text-align: center;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        color: #3e280e;

        @media (max-width: 1680px) {
          font-size: 26px;
          line-height: 32.53px;
          text-align: center;
        }

        @media (max-width: 553px) {
          font-size: 16px;
          line-height: 20.02px;
        }
        @media (max-width: 350px) {
          width: 95%;
        }
      }
    }

    .plitka:nth-child(1) {
      margin-right: 160px;
      padding-top: 20px;

      @media (max-width: 1680px) {
        margin-right: 0;
        padding-top: 59px;
      }

      @media (max-width: 553px) {
        padding-top: 9px;
      }

      img {
        width: 475.45px;
        height: 163.82px;
        padding-right: 0;

        @media (max-width: 1680px) {
          width: 342.55px;
          height: 118.02px;
          margin: 0 auto;
        }

        @media (max-width: 553px) {
          width: 316.55px;
          height: 109.07px;
        }
      }
      p {
        margin: 0 auto;
        width: 423px;
        margin-top: 66px;

        @media (max-width: 1680px) {
          width: auto;
          margin-top: 20px;
        }

        @media (max-width: 553px) {
          margin-top: 9px;
        }
      }
      a {
        margin: 0 auto;
        margin-top: 30px;

        @media (max-width: 553px) {
          margin-top: 22px;
        }
      }
    }
    .plitka:nth-child(2) {
      margin-right: 269px;
      margin-top: 11px;

      @media (max-width: 1680px) {
        margin-right: 0;
        margin-top: 80px;
      }

      @media (max-width: 553px) {
        margin-top: 58px;
      }
      img {
        width: 321px;
        height: 354px;
        margin: 0 auto;

        @media (max-width: 1680px) {
          width: 232px;
          height: 255.85px;
        }

        @media (max-width: 553px) {
          width: 246.77px;
          height: 272.14px;
        }
      }
      p {
        margin-top: 30px;

        @media (max-width: 1680px) {
          margin-top: 20px;
        }

        @media (max-width: 553px) {
          margin-top: 9px;
        }
      }
      a {
        margin-top: 69px;

        @media (max-width: 1680px) {
          margin: 0 auto;
          margin-top: 32px;
        }

        @media (max-width: 553px) {
          margin-top: 21px;
        }
      }
    }
    .plitka:nth-child(3) {
      transform: translateX(-60px);

      @media (max-width: 1680px) {
        margin-right: 0;
        transform: none;
        margin-top: 100px;
      }

      @media (max-width: 553px) {
        margin-top: 69px;
      }

      img {
        width: 368.74px;
        height: 263px;
        margin: 0 auto;

        @media (max-width: 1680px) {
          width: 280.8px;
          height: 200.28px;
        }

        @media (max-width: 553px) {
          width: 278.55px;
          height: 198.67px;
        }
      }
      p {
        margin-top: 30px;
        margin-left: -7px;

        @media (max-width: 1680px) {
          margin-left: 0;
          margin-top: 18px;
        }

        @media (max-width: 553px) {
          margin-top: 9px;
        }
      }
      a {
        margin-top: 69px;
        margin-left: 10px;

        @media (max-width: 1680px) {
          margin: 0 auto;
          margin-top: 32px;
        }

        @media (max-width: 553px) {
          margin-top: 22px;
        }
      }
    }
  }
  .uzor {
    position: absolute;
    left: 0;
    bottom: -141px;
    z-index: 10;

    @media (max-width: 553px) {
      display: none;
    }

    @media (max-width: 933px) {
      display: none;
    }
  }
}
