@import "../../../styles/mixins.scss";

.HomePageConstruction {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 161px;

  @media (max-width: $media1) {
    margin-top: 36px;
  }

  @media (max-width: $media2) {
    margin-top: -14px;
  }

  .container {
    width: 1390px;
    padding-left: 130px;

    @media (max-width: $media1) {
      width: 750px;
      padding-left: 30px;
    }

    @media (max-width: $media2) {
      max-width: 375px;
      width: auto;
      padding-left: 0;
    }

    h2 {
      letter-spacing: -1.7px;
      font-family: "SPSL-New-Cyrillic";
      color: #3e280e;
      //styleName: заголовок;
      font-family: SPSL-New-Cyrillic;
      font-size: 80px;
      font-weight: 400;
      line-height: 68.8px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      padding: 0;
      margin: 0;

      @media (max-width: $media1) {
        font-size: 50px;
        font-weight: 400;
        line-height: 43px;
        text-align: left;
        letter-spacing: -1px;
      }

      @media (max-width: $media2) {
        font-size: 24px;
        font-weight: 400;
        line-height: 20.64px;
        text-align: center;
        letter-spacing: -0.3px;
      }
    }
    .box {
      display: flex;
      flex-direction: row;

      @media (max-width: $media2) {
        flex-direction: column;
      }

      .left {
        position: relative;
        @media (max-width: $media1) {
          width: 293px;
        }
        @media (max-width: $media2) {
          width: 100%;
          position: relative;
        }

        p {
          font-family: "Sitka";
          color: #3e280e;
          text-wrap: nowrap;
          margin-left: 0;

          font-size: 32px;
          font-weight: 400;
          line-height: 40.04px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
          letter-spacing: 0.3px;
          padding: 0;
          margin: 0;
          margin-top: 20px;

          @media (max-width: $media1) {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
            line-height: 25.02px;
            text-wrap: wrap;
            br {
              display: none;
            }
          }

          @media (max-width: $media2) {
            font-size: 16px;
            font-weight: 400;
            line-height: 20.02px;
            margin-top: 0;
            padding: 0 17px;
          }
        }
        p:nth-child(1) {
          margin-top: 115px;
          padding-top: 0;
          @media (max-width: $media1) {
            margin-top: 44px;
          }
          @media (max-width: $media2) {
            margin-top: 18px;
            padding: 0 17px;
          }
        }
        button {
          @include Button();
          padding: 30px 87px;
          padding-left: 83px;
          box-shadow: 3px 3px 3px 0px #402a10;
          width: 480px;
          height: 111px;
          margin-top: 75px;
          font-family: SPSL-New-Cyrillic;
          font-size: 60px;
          font-weight: 400;
          line-height: 51.6px;
          text-align: center;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;

          @media (max-width: $media1) {
            width: 293px;
            font-size: 30px;
            font-weight: 400;
            line-height: 4.8px;
            letter-spacing: -0.01em;
            padding: 28px 30px;
            padding-left: 42px;
            margin-top: 32px;
            height: 26px;
            text-align: center;
          }
          @media (max-width: $media2) {
            display: none;
            position: absolute;
            top: 358px;
            margin: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 339px;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            line-height: 0;
            letter-spacing: -0.01em;
            padding-bottom: 19px;
            padding-left: 30px;
          }
        }
        .uzor {
          top: 409.3px;
          left: 433px;
          position: absolute;
          width: 257.97px;
          @media (max-width: $media1) {
            display: none;
          }
        }
      }
      .rigth {
        margin-left: 209px;
        @media (max-width: $media1) {
          margin-left: 56px;
        }
        @media (max-width: $media2) {
          margin-left: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 10px;
        }

        .btnRigl {
          display: none;
          @include Button();
          @media (max-width: $media2) {
            padding: 28px 30px;
            display: block;
            font-family: SPSL-New-Cyrillic;
            text-underline-position: from-font;
            -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
            max-width: 339px;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            line-height: 0;
            letter-spacing: -0.01em;
            padding-bottom: 23px;
            padding-left: 30px;
            margin: 0 auto;
            margin-top: 10px;
            text-align: center;
          }
          @media (max-width: 355px) {
            top: 378px;
            width: 98%;
          }
        }

        img {
          margin-top: 77px;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: top;
          object-position: top;
          border: 4px solid #341f07;
          border-radius: 10px;
          @media (max-width: $media1) {
            width: 372px;
            height: 235px;
            margin-top: 46px;
          }

          @media (max-width: $media2) {
            margin: 0 auto;
            max-width: 339px;
            width: 100%;
            height: 204px;
            margin-top: 21px;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: top;
            object-position: top;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border: 2px solid #341f07;
            // border: 4px solid #341f07;
          }
        }
      }
    }
  }
}
