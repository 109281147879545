@import "../../styles/mixins.scss";

.SchedulePage {
  min-height: 100vh;

  padding-top: 114px;

  @media (max-width: 1520px) {
    padding-top: 87px;
  }

  @media (max-width: 750px) {
    padding-top: 30px;
  }

  // @media (max-width: $media1) {
  //   padding-top: 0;
  // }

  h2 {
    @include H2();
    @include GradientSpan();
    font-family: SPSL-New-Cyrillic;
    font-size: 80px;
    font-weight: 400;
    line-height: 68.8px;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    margin-top: 53px;

    @media (max-width: 1520px) {
      font-size: 50px;
      line-height: 43px;
    }

    @media (max-width: 750px) {
      font-size: 26px;
      line-height: 22.36px;
    }
  }

  .container {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 1350px;

    @media (max-width: 1520px) {
      width: 720px;
      position: relative;
    }

    @media (max-width: 750px) {
      width: 100%;
    }
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 36px;
    width: 90%;

    @media (max-width: 1520px) {
      margin-bottom: 82px;
      width: auto;
    }

    @media (max-width: 750px) {
      margin-bottom: 0;
    }
  }

  .left {
    color: #3e280e;
    position: relative;
    width: 360px;

    @media (max-width: 1520px) {
      position: absolute;
      left: 0;
      top: 100px;
    }

    @media (max-width: 750px) {
      position: relative;
      top: auto;
      width: 90vw;
      margin: 0 auto;
      margin-bottom: 0px;
    }
    // @media (max-width: 365px) {
    //   position: relative;
    //   top: auto;
    //   width: 90vw;
    // }
  }

  .centr {
    font-family: "SPSL-New-Cyrillic";
    font-weight: 400;
    text-align: left;
    color: #3e280e;
  }

  .rigth {
    color: #3e280e;
    @media (max-width: 1520px) {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }

  .sedule_box {
    display: flex;
    margin: 0 auto;
    // width: 80vw;
    min-height: 60vh;
    flex-direction: column;

    @media (max-width: 1520px) {
      margin-bottom: 60px;
    }

    @media (max-width: 750px) {
      margin: 0 auto;
      width: 90%;
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }

  .noData {
    display: flex;
    font-family: "Sitka";

    color: #3e280e;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    font-family: Sitka;
    font-size: 40px;
    font-weight: 400;
    line-height: 34.42px;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: 1520px) {
      width: 720px;
      border-bottom: 1px solid #000;
    }

    @media (max-width: 750px) {
      width: 100%;
      border-bottom: none;

      font-family: Sitka;
      font-size: 20px;
      font-weight: 400;
      line-height: 22.52px;
      text-align: center;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
    }
  }

  .Loader {
    display: flex;
    font-family: "Sitka";
    font-size: 2vw;
    font-weight: 400;
    text-align: left;
    color: #3e280e;
    margin-left: 4vw;
    justify-content: center;
    align-items: center;
    padding-top: 10vw;
  }

  .loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: rotate 1s linear infinite;
    animation: rotate 1s linear infinite;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 8px solid #3e280e;
      -webkit-animation: prixClipFix 2s linear infinite;
      animation: prixClipFix 2s linear infinite;
    }
    @-webkit-keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }

    @keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }
  }

  .bottonNavigagte {
    display: flex;
    margin: 0 auto;
    width: 1300px;
    justify-content: space-between;
    color: #3e280e;
    margin-bottom: 4vw;

    @media (max-width: 1520px) {
      width: 720px;
    }

    @media (max-width: 750px) {
      display: none;
    }
    span {
      margin: 0 20px;
      font-family: Sitka;
      font-size: 32px;
      font-weight: 400;
      line-height: 40.04px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      padding-bottom: 4px;

      @media (max-width: 1520px) {
        font-size: 24px;
        line-height: 30.03px;
        margin: 0 10px;
        padding-top: 5px;
      }
    }
    img {
      width: 49px;
      height: 34px;

      @media (max-width: 1520px) {
        width: 31px;
        height: 22px;
      }
    }

    img:nth-child(2) {
      padding-bottom: 5px;
    }
    .bottonNavigagteInner {
      display: flex;
      align-items: center;
      cursor: pointer;
      & {
        @include Hover();
      }
    }
  }
}
