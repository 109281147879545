@font-face {
  font-family: "SPSL-New-Cyrillic";
  src: url("./../fonts/spsl-new-cyrillic.woff2") format("woff2"),
    url("./../fonts/spsl-new-cyrillic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sitca";
  src: url("./../fonts/sitka-small.woff2") format("woff2"),
    url("./../fonts/sitka-small.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("./../fonts/SegoeUI.woff2") format("woff2"),
    url("./../fonts/SegoeUI.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #fdf1dc;
  color: #fff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
