@import "../../styles/mixins.scss";

.Footer {
  display: flex;
  justify-content: center;
  background-color: #341f07;
  position: relative;
  flex-direction: column;
  @media (max-width: 760px) {
    padding: 18px;
  }
  .container {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    width: 1520px;
    margin: 0 auto;
    @media (max-width: 1730px) {
      width: 720px;
    }

    @media (max-width: 760px) {
      width: 100%;
      flex-direction: column;
      padding-top: 0px;
    }

    h2 {
      @include H3;
      padding-top: 0;
      margin-top: 0;
      @include GradientText();
      font-family: SPSL-New-Cyrillic;
      font-size: 70.54px;
      font-weight: 400;
      line-height: 60.67px;

      @media (max-width: 1730px) {
        font-size: 44.37px;
        line-height: 38.16px;
        text-align: right;
      }
      @media (max-width: 760px) {
        font-size: 36px;
        line-height: 30.96px;
        width: 130px;
        position: absolute;
        right: 18px;
        top: 18px;
      }
    }
    div {
      a {
        text-decoration: none;
        outline: none;
        width: auto;
        display: inline;
        cursor: pointer;
        @include Hover;
        color: #fdf1dc;
      }
      @media (max-width: 760px) {
        p:nth-child(1) {
          margin-top: 0;
        }
      }

      p {
        flex-shrink: 0;
        font-family: "SPSL-New-Cyrillic";
        color: #fdf1dc;
        text-decoration: none;
        outline: none;
        font-size: 28px;
        font-weight: 400;
        line-height: 24.08px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        padding-top: 0;
        padding-bottom: 12px;
        margin-top: 10px;
        text-wrap: nowrap;
        @media (max-width: 1730px) {
          font-size: 20px;
          line-height: 17.2px;
          padding-bottom: 3px;
        }
        @media (max-width: 760px) {
          font-size: 20px;
          line-height: 17.2px;
          padding-bottom: 13px;
        }
      }
    }

    .block1 {
      width: 495px;
      margin-right: 40px;
      @media (max-width: 1730px) {
        width: 153px;
        margin-right: 91px;
      }
    }
    .block2 {
      width: 350px;
      margin-right: 122px;
      @media (max-width: 1730px) {
        width: 187px;
        margin-right: 98px;
      }
    }
  }
  .pravaInfo {
    display: flex;
    flex-direction: row;
    width: 1520px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 1730px) {
      width: 720px;
      flex-direction: column;
      margin-bottom: 60px;
    }

    @media (max-width: 760px) {
      width: 100%;
      margin-bottom: 160px;
    }

    div {
      display: flex;
      flex-direction: column;
      p {
        font-family: Segoe UI;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.3px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        color: #6d5943;

        @media (max-width: 1730px) {
          font-size: 13px;
          line-height: 17.3px;
        }

        @media (max-width: 760px) {
          font-size: 13px;
          line-height: 17.3px;
        }
      }
    }

    .pravaInfoBox1 {
      width: 497px;
      margin-right: 38px;
      @media (max-width: 1730px) {
        width: 444px;
        margin: 0;
        margin-top: 40px;
        p {
          margin: 0;
          margin-bottom: 18px;
        }
      }
      @media (max-width: 760px) {
        margin-top: 10px;
        width: 100%;
        max-width: 400px;
        min-width: 300px;
      }
    }
    .pravaInfoBox2 {
      width: 350px;
      margin-right: 60px;
      @media (max-width: 1730px) {
        width: 444px;
        margin: 0;
        p {
          margin: 0;
        }
      }
      @media (max-width: 760px) {
        width: 100%;
        max-width: 400px;
        min-width: 300px;
      }
    }
    .pravaInfoBox3 {
      display: flex;
      justify-content: end;
      height: 100px;
      margin-right: 117px;
      text-wrap: nowrap;
      @media (max-width: 1730px) {
        display: none;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }

    .pravaInfoBox4 {
      transform: translateY(-10px);
      width: 288px;
      display: flex;
      justify-content: end;
      @media (max-width: 1730px) {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: none;
      }
      @media (max-width: 760px) {
        right: auto;
        left: 0;
        top: 317px;
      }
      p {
        padding: 0;
        margin: 0;
        padding-bottom: 8px;
        font-family: Segoe UI;
        font-size: 18px;
        font-weight: 400;
        line-height: 23.96px;
        text-align: right;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        color: #fdf1dc;
        text-wrap: nowrap;

        @media (max-width: 1730px) {
          font-size: 16px;
          line-height: 21.3px;
        }
        @media (max-width: 760px) {
          text-align: left;
        }
      }
      @media (max-width: 1730px) {
        p:nth-child(3) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .pravanout {
    display: none;
    position: absolute;
    right: 20px;
    bottom: 0px;
    width: 164px;
    color: #6d5943;
    font-family: SPSL-New-Cyrillic;
    font-size: 18px;
    font-weight: 400;
    line-height: 15.48px;
    text-align: right;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: 1730px) {
      display: block;
    }
    @media (max-width: 760px) {
      font-size: 12px;
      line-height: 10.32px;
    }
  }
}
