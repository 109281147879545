.Payment {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0000008a;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    position: relative;
  }
  .mixplatForm {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .close {
    position: absolute;
    z-index: 1000;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: all 0.15s ease;
    }
  }
}
