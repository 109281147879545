.NewsPage {
  width: 1520px;
  min-height: 70vh;
  padding-top: 114px;
  @media (max-width: 1560px) {
    padding-top: 80px;

    width: 720px;
  }
  @media (max-width: 760px) {
    width: 375px;
    padding-top: 64px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
  .newData {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 134px;
    margin-top: 85px;
    span {
      font-family: SPSL-New-Cyrillic;
      font-size: 40px;
      font-weight: 400;
      line-height: 34.42px;
      text-align: left;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      -webkit-text-decoration-style: solid;
      text-decoration-style: solid;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      color: #000000;
      text-decoration: underline;
      cursor: pointer;

      @media (max-width: 1520px) {
        font-size: 30px;
        line-height: 25.82px;
      }

      @media (max-width: 720) {
        font-size: 22px;
      }

      &:hover {
        opacity: 0.5;
        transition: all 0.1s linear;
      }
      &:active {
        opacity: 0.3;
        transition: all 0.1s linear;
      }
    }
  }

  h1 {
    font-size: 4vw;
    font-weight: 400;
    margin-top: 4vw;
    margin-bottom: 5vw;
    text-align: center;
    background: linear-gradient(
      89.52deg,
      #341f07 0.22%,
      #c39d71 13.52%,
      #341f07 32.61%,
      #c39d71 75.42%,
      #341f07 93.1%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "SPSL-New-Cyrillic", arial;

    @media (max-width: 1520px) {
      font-size: 50px;
    }
    @media (max-width: 760px) {
      font-family: SPSL-New-Cyrillic;
      font-size: 26px;
      font-weight: 400;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-bottom: 20px;
      padding: 0;
    }
    @media (max-width: 720) {
      font-family: SPSL-New-Cyrillic;
      font-size: 26px;
    }
  }
  h2 {
    font-size: 3vw;
    font-weight: 400;
    margin-top: 4vw;
    margin-bottom: 8vw;
    text-align: center;
    background: linear-gradient(
      89.52deg,
      #341f07 0.22%,
      #c39d71 13.52%,
      #341f07 32.61%,
      #c39d71 75.42%,
      #341f07 93.1%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "SPSL-New-Cyrillic", arial;
  }
  .NewsPage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Loader {
    display: flex;
    font-family: "Sitka";
    font-size: 2vw;
    font-weight: 400;
    text-align: left;
    color: #3e280e;
    // margin-left: 4vw;
    justify-content: center;
    align-items: center;
    padding-bottom: 20vw;
  }

  .loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: rotate 1s linear infinite;
    animation: rotate 1s linear infinite;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 8px solid #3e280e;
      -webkit-animation: prixClipFix 2s linear infinite;
      animation: prixClipFix 2s linear infinite;
    }
    @-webkit-keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }

    @keyframes prixClipFix {
      0% {
        -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 0,
          100% 0,
          100% 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
        clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          100% 100%,
          100% 100%
        );
      }
      75% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 100%
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        -webkit-clip-path: polygon(
          50% 50%,
          0 0,
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }
  }
}
