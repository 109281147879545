@import "./../../../styles/mixins.scss";

.HomeModuleContacts {
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 1419px;
    margin: 0 auto;
    margin-top: 10vw;
    padding-right: 98px;
    box-sizing: border-box;

    @media (max-width: $media1) {
      width: 750px;
      margin-top: 130px;
      padding-right: 0;
    }

    @media (max-width: $media2) {
      margin-top: 80px;
      max-width: 375px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    h2 {
      font-family: "SPSL-New-Cyrillic";
      text-align: center;
      color: #3e280e;
      padding: 0;
      margin: 0;
      margin-top: 82px;
      font-size: 80px;
      font-weight: 400;
      line-height: 68.8px;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      @media (max-width: $media1) {
        font-size: 50px;
        font-weight: 400;
        line-height: 43px;
        margin-top: 0;
        text-align: right;
        padding-right: 16px;
        padding-top: 21px;
      }

      @media (max-width: $media2) {
        font-size: 24px;
        font-weight: 400;
        line-height: 20.64px;
        margin: 0;
        padding: 0;
        text-align: center;
        padding-top: 11px;
      }
    }
    .box {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      margin-top: 88px;

      @media (max-width: $media1) {
        margin-top: 0px;
        justify-content: left;
        margin: 0;
      }

      @media (max-width: $media2) {
        flex-direction: column;
        justify-content: center;
      }
      .left {
        @media (max-width: $media1) {
          width: 354px;
        }
        @media (max-width: $media2) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        img {
          transform: translateX(-118px) translateY(-51px);
          width: 663px;
          height: 706px;

          @media (max-width: $media1) {
            width: 368px;
            height: 391.87px;
            transform: translateX(-35px) translateY(-69px);
          }

          @media (max-width: $media2) {
            width: 299px;
            height: 318.39px;
            transform: none;
            padding-right: 49px;
            padding-top: 10px;
          }
          @media (max-width: 375px) {
            margin: 0 auto;
            width: 249px;
            height: auto;
            transform: translateX(-20px);
            padding-right: 0;
          }
        }
      }
      .rigth {
        padding-left: 30px;

        @media (max-width: $media1) {
          padding-left: 55px;
          padding-top: 40px;
        }
        @media (max-width: $media2) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0;
          padding: 0;
        }
        a:nth-child(1) {
          padding-top: 24px;

          @media (max-width: $media1) {
            padding-top: 30px;
            padding-bottom: 20px;
          }

          @media (max-width: $media2) {
            display: flex;
            width: 237px;
            margin: 0 auto;
            padding-top: 18px;
            padding: 7px 0px;
            margin-top: 13px;
            border-radius: 8px;
            &:hover {
              background-color: rgba(240, 224, 198, 1);
            }
          }
        }
        a:nth-child(2) {
          @media (max-width: $media2) {
            display: flex;
            width: 237px;
            margin: 0 auto;
            padding-top: 18px;
            padding: 7px 0px;
            margin-top: 2px;
            margin-bottom: 31px;
            border-radius: 8px;
            &:hover {
              background-color: rgba(240, 224, 198, 1);
            }
          }
        }
      }
      h3 {
        font-family: "SPSL-New-Cyrillic";
        font-size: 3vw;
        font-weight: 400;
        line-height: 86px;
        text-align: left;
        color: #3e280e;

        @media (max-width: $media1) {
          font-size: 30px;
          font-weight: 400;
          line-height: 25.82px;
          padding: 0;
          margin: 0;
          padding-top: 29px;
        }
        @media (max-width: $media2) {
          text-align: center;
          font-size: 22px;
          font-weight: 400;
          line-height: 18.93px;
          padding-top: 8px;
        }
      }
      a {
        text-decoration: none;
        outline: none;
        font-family: "Sitka";
        text-align: left;
        color: #3e280e;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        font-size: 50px;
        font-weight: 400;
        line-height: 62.55px;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        padding: 0;
        margin: 0;
        padding-bottom: 40px;

        @media (max-width: $media1) {
          font-size: 26px;
          font-weight: 400;
          line-height: 32.53px;
        }
        @media (max-width: $media2) {
          display: flex;
          justify-content: center;
          font-size: 22px;
          font-weight: 400;
          line-height: 27.52px;
          margin-top: 8px;
        }
        img {
          margin-right: 40px;
          width: 90px;
          height: 90px;

          @media (max-width: $media1) {
            width: 35px;
            height: 35px;
            margin-right: 20px;
          }
          @media (max-width: $media2) {
            margin-right: 6px;
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}
