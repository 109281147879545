.SchedulePlitka {
  margin-bottom: 65px;
  width: 1300px;

  @media (max-width: 1520px) {
    width: 720px;
    margin-bottom: 15px;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 0px;
  }

  .title {
    width: 100%;
    border-bottom: 1px solid #000;
    margin-bottom: 0;

    @media (max-width: 1520px) {
      margin-bottom: 15px;
    }

    @media (max-width: 750px) {
      margin-bottom: 8px;
    }
    p {
      color: #bea881;
      font-family: Sitka;
      font-size: 26px;
      font-weight: 400;
      line-height: 32.53px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      // padding-left: 24px;
      padding-bottom: 0;
      margin-bottom: 6px;

      @media (max-width: 1520px) {
        font-size: 22px;
        line-height: 27.52px;
      }

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 22.52px;
      }
    }
  }
  .box {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }
  .boxInner {
    display: flex;
    flex-direction: row;
    // margin: 1.5vw 0;
    margin: 32px 0;

    @media (max-width: 1520px) {
      margin: 15px 0;
    }

    @media (max-width: 750px) {
      width: 100%;
      margin: 8px 0;
    }
    p {
      color: #3e280e;
      margin: 0;
      padding: 0;
      padding: 10px 10px;
      font-family: SPSL-New-Cyrillic;
      font-size: 40px;
      font-weight: 400;
      line-height: 34.42px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;

      @media (max-width: 1520px) {
        font-size: 30px;
        line-height: 25.82px;
      }

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 22.52px;
        padding: 0;
      }
    }
    span {
      color: #3e280e;
      border-right: 0.1vw solid #000;
      margin-right: 23px;
      padding-right: 33px;
      padding-left: 10px;

      font-family: Sitka;
      font-size: 44px;
      font-weight: 600;
      line-height: 55.05px;
      text-align: left;
      text-underline-position: from-font;

      @media (max-width: 1520px) {
        font-family: Sitka;
        font-size: 30px;
        line-height: 37.53px;
        text-align: center;
      }

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 22.52px;
        border-right: none;
        padding-right: 15px;
        margin-right: 0px;

        &::after {
          content: "-";
          padding-left: 15px;
          height: 4px;
        }
      }
    }
  }
}
