.swiper {
  overflow: hidden;
}

.swiper .swiper-slide {
  transition: transform 1.5s ease;
  position: relative;
}

.swiper .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.3;
  z-index: 1;
}
.swiper .swiper-slide-prev {
  transform: scale(0.6) translateX(65%);
}

.swiper .swiper-slide-next {
  transform: scale(0.6) translateX(-70%);
}

.swiper .swiper-slide.swiper-slide-active {
  transform: scale(1);
  opacity: 1;
  z-index: 2;
}

.swiper-button-prev {
  left: 22.5%;
  background: linear-gradient(
    89.52deg,
    #341f07 0.22%,
    #c39d71 13.52%,
    #341f07 32.61%,
    #c39d71 75.42%,
    #341f07 93.1%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 24px; /* Увеличиваем размер иконок */
  font-weight: 700; /* Делаем иконки более жирными */
  transform: scaleX(0.6);
  width: auto;
}

.swiper-button-next {
  right: 28%;
  background: linear-gradient(
    89.52deg,
    #341f07 0.22%,
    #c39d71 13.52%,
    #341f07 32.61%,
    #c39d71 75.42%,
    #341f07 93.1%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold; /* Делаем иконки более жирными */
  transform: scaleX(0.7);
  width: auto;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 104px; /* Оставляем размер иконок */
  font-weight: bold; /* Делаем иконки более жирными */
  color: #333; /* Меняем цвет иконок */
}
