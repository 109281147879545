@import "./../../styles/mixins.scss";

.TempleCalendarPage {
  min-height: 100vh;
  padding-top: 114px;

  @media (max-width: 1720px) {
    padding-top: 50px;
  }

  @media (max-width: 1560px) {
    padding-top: 114px;
  }

  @media (max-width: 929px) {
    padding-top: 60px;
  }
  @media (max-width: 750px) {
    padding: 13px;
    padding-top: 70px;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  h2 {
    @include H2;
    @include GradientTitle;

    //styleName: заголовок;
    font-family: SPSL-New-Cyrillic;
    font-size: 80px;
    font-weight: 400;
    line-height: 115.8px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;

    @media (max-width: 1560px) {
      font-size: 50px;
      line-height: 43px;
    }
    @media (max-width: 750px) {
      font-size: 26px;
      line-height: 22.36px;
    }
  }

  .holidaysMin {
    display: none;
    padding-left: 6px;
    margin-top: 30px;
    @media (max-width: 750px) {
      display: block;
    }
    h3 {
      font-family: SPSL-New-Cyrillic;
      font-size: 20px;
      font-weight: 400;
      line-height: 17.21px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      color: #3e280e;
      margin-top: 0;
    }
    span {
      color: #3e280e;
      font-family: Sitka;
      font-size: 18px;
      font-style: italic;
      font-weight: 600;
      line-height: 22.52px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
    }
    p {
      font-family: Sitka;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      color: #c24743;
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 0 auto;

    @media (max-width: 1560px) {
      width: 720px;
      margin-top: 40px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
      width: 100%;
      margin-top: 0;
    }

    .calendarText {
      display: none;

      @media (max-width: 750px) {
        width: 100%;
        padding-left: 6px;
        box-sizing: border-box;
        // width: 354px;
        // margin: 0 auto;
        display: flex;
        background-color: #fdf1dc;
        font-family: SPSL-New-Cyrillic;
        font-size: 22px;
        font-weight: 400;
        line-height: 21.64px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        justify-content: center;

        .calendarTextInner {
          display: flex;
          width: auto;
          cursor: pointer;
        }

        p {
          padding-left: 0;
          margin: 0;
          box-sizing: border-box;
          border: 1px solid #fdf1dc;
          outline: none;
          color: #3e280e;
          padding: 7px 21px;
          border-radius: 5px;
          border: 1px solid #341f07;
          margin-bottom: 20px;
        }
        img {
          width: 12px;
          transition: all 0.3s ease;
          margin: 0 4px;
        }
      }
    }

    .calendar1 {
      margin-top: 8px;
      width: 480px;
      display: flex;
      flex-direction: column;
      transform: translateX(-30px);

      @media (max-width: 1560px) {
        transform: translateX(0px);
        margin-right: 12px;
      }
      @media (max-width: 750px) {
        width: 354px;
        margin: 0 auto;
      }

      @media (max-width: 380px) {
        width: 100%;
      }

      .ok {
        display: none;
        @media (max-width: 750px) {
          display: block;
          position: absolute;
          display: flex;
          right: 29px;
          bottom: 0;
          color: #341f07;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .holidays {
        @media (max-width: 750px) {
          display: none;
        }
        p {
          color: #3e280e;
        }

        h3 {
          color: #3e280e;
          font-family: SPSL-New-Cyrillic;
          font-size: 40px;
          font-weight: 400;
          line-height: 34.42px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
          margin-bottom: 32px;

          @media (max-width: 1560px) {
            font-family: SPSL-New-Cyrillic;
            font-size: 28px;
            line-height: 24.1px;
            p {
              font-style: 28px;
            }
          }
        }
        .holidaysInner {
          span {
            color: #3e280e;
            font-family: Sitka;
            font-size: 26px;
            font-style: italic;
            font-weight: 600;
            line-height: 32.53px;
            text-align: left;
            text-underline-position: from-font;
            -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;

            @media (max-width: 1560px) {
              font-size: 22px;
              font-style: italic;
              font-weight: 600;
              line-height: 27.52px;
            }
          }
          p {
            padding-top: 0;
            margin-top: 0;
            font-family: Sitka;
            font-size: 26px;
            font-weight: 400;
            line-height: 32.53px;
            text-align: left;
            text-underline-position: from-font;
            -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
            color: #c24743;

            @media (max-width: 1560px) {
              font-size: 22px;
              line-height: 27.52px;
            }
          }
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 1000px;
      box-sizing: border-box;

      @media (max-width: 1560px) {
        width: 350px;
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-top: 23px;
        padding-left: 6px;
      }

      h3 {
        //styleName: заголовок2;
        font-family: SPSL-New-Cyrillic;
        font-size: 60px;
        font-weight: 400;
        line-height: 51.64px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        color: #3e280e;
        margin: 0;
        padding: 0;
        padding-left: 9px;
        display: flex;
        flex-direction: row;
        text-wrap: nowrap;
        transform: translateY(-3px);

        @media (max-width: 1560px) {
          font-size: 38px;
          line-height: 32.7px;
          transform: none;
        }
        @media (max-width: 750px) {
          font-size: 22px;
          line-height: 18.93px;
          padding-left: 0;
          transform: none;
        }

        p {
          display: flex;
          font-family: Sitka;
          font-size: 44px;
          font-weight: 600;
          line-height: 55.05px;
          text-align: left;
          text-underline-position: from-font;
          -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
          margin: 0;
          padding: 0;
          align-items: center;
          margin-left: 25px;

          @media (max-width: 1560px) {
            font-size: 28px;
            font-weight: 600;
            line-height: 35.03px;
            margin-left: 10px;
          }

          @media (max-width: 750px) {
            font-size: 20px;
            line-height: 18.02px;
          }
        }
      }

      .holidays {
        display: flex;
        height: 100%;
        padding-left: 12px;

        @media (max-width: 750px) {
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
  }

  .holidays {
    @media (max-width: 750px) {
      display: none;
    }
    h3 {
      color: #3e280e;
      font-family: SPSL-New-Cyrillic;
      font-size: 40px;
      font-weight: 400;
      line-height: 34.42px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      margin-bottom: 32px;

      @media (max-width: 1560px) {
        font-family: SPSL-New-Cyrillic;
        font-size: 28px;
        line-height: 24.1px;
        p {
          font-style: 28px;
        }
      }
    }
  }

  .bottonNavigagte {
    display: flex;
    margin: 0 auto;
    width: 1000px;
    justify-content: space-between;
    color: #3e280e;
    padding-bottom: 60px;
    padding-left: 12px;

    @media (max-width: 1560px) {
      position: absolute;
      top: 220px;
      left: 50%;
      transform: translateX(-50%);
      width: 720px;
      padding-left: 0px;
    }

    @media (max-width: 929px) {
      top: 170px;
    }

    @media (max-width: 750px) {
      display: none;
    }

    span {
      font-family: Sitka;
      font-size: 32px;
      font-weight: 400;
      line-height: 40.04px;
      text-align: left;
      text-underline-position: from-font;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      margin: 0 20px;

      @media (max-width: 1560px) {
        font-family: Sitka;
        font-size: 24px;
        font-weight: 400;
        line-height: 30.03px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        margin: 0 10px;
      }
    }
    img {
      width: 49px;

      @media (max-width: 1560px) {
        width: 31px;
      }
    }
    .bottonNavigagteInner {
      display: flex;
      align-items: center;
      cursor: pointer;
      & {
        @include Hover();
      }
    }
  }
}
