@font-face {
  font-family: "Sitca";
  src: url("/fonts/sitka-small.woff2") format("woff2"),
    url("/fonts/sitka-small.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.SoulsModule {
  padding-top: 184px;
  @media (max-width: 1335px) {
    padding-top: 134px;
  }
  @media (max-width: 928px) {
    padding-top: 114px;
  }
  .SoulsModuleInner {
    // display: flex;
    h3 {
      font-family: "SPSL-New-Cyrillic";
      font-size: 80px;
      position: relative;
      // left: 20px;
      font-weight: 400;
      line-height: 86px;
      text-align: center;
      color: #3e280e;
      margin-bottom: 90px;
      margin-top: 0px;
      @media (max-width: 1520px) {
        font-size: 55px;
        margin-bottom: 15px;
        //  margin-left: 185px;
        //  left: 0;
        top: -25px;
      }
      @media (max-width: 890px) {
        font-size: 26px;
        line-height: 22.36px;
        margin-bottom: 0;
      }
    }
  }
}
.imgBoxContainerPhone {
  display: none;
  @media (max-width: 1520px) {
    display: flex;
    justify-content: start;
    margin-left: 135px;
    position: relative;
    margin-bottom: 45px;
  }
  @media (max-width: 890px) {
    justify-content: center;
    margin-left: 0px;
    width: 100%;
    margin: 0 auto;
  }

  .imgFirst {
    width: 285px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    // @media (max-width: 460px) {
    //   width: 260px;
    //   margin: 0 auto;
    // }
  }
  .caracumPhone {
    position: absolute;
    left: -85px;
    bottom: -45px;
    width: 190px;
    @media (max-width: 890px) {
      bottom: 60px;
      left: -72px;
      width: 140px;
    }
    @media (max-width: 424px) {
      display: none;
    }
  }
  .imgBoxPhone {
    display: flex;
    margin: 0 auto;
    color: #3e280e;
    @media (max-width: 890px) {
      display: block;
      position: relative;
    }
  }
  .titlePhone {
    font-size: 30px;
    margin-left: 12px;
    @media (max-width: 890px) {
      margin-left: 0;
    }
    .titlePhoneText {
      font-size: 30px;
      font-weight: 600;
      @media (max-width: 890px) {
        font-size: 26px;
        text-align: center;
        margin-top: 15px;
      }
      @media (max-width: 690px) {
        font-size: 24px;
      }
    }
    .SubtitlePhone {
      margin: 0px;
      font-size: 20px;
      position: relative;
      top: -20px;
      @media (max-width: 890px) {
        font-size: 18px;
        text-align: center;
      }
    }

    p {
      font-family: "Sitca" !important;
      margin: 0;
      margin-bottom: 25px;
      span {
        font-size: 20px;
      }
    }
    .DatePhone {
      @media (max-width: 890px) {
        display: none;
      }
    }
  }
}

.SoulsModuleInnerTextContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.SoulsModuleInnerText {
  display: flex;
  width: 100%;
  @media (max-width: 1520px) {
    display: block;
    width: 900px;
  }
  .text {
    width: 870px;
    font-size: 18px;
    font-size: 26px;
    font-weight: 400;
    line-height: 32.53px;
    text-align: left;
    color: #3e280e;
    margin-left: 40px;
    margin-top: 10px;
    @media (max-width: 1520px) {
      margin-left: 135px;
      margin-top: 85px;
      width: 720px;
    }
    @media (max-width: 890px) {
      width: 340px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 20px;
      padding: 0 18px;
    }
    @media (max-width: 380px) {
      width: 90%;
      padding: 0;
    }
    .title {
      font-size: 40px;
      margin: 0;
      font-family: "SPSL-New-Cyrillic";
      @media (max-width: 1520px) {
        display: none;
      }
    }
    .date {
      @media (max-width: 1520px) {
        display: none;
      }
      @media (max-width: 890px) {
        display: block;
      }
    }
    p {
      margin: 0;
      font-family: "Sitca";
      font-weight: 100;
      &:nth-child(2) {
        margin-top: 40px;
        margin-bottom: 10px;
      }
      &:nth-child(3) {
        margin-bottom: 40px;
      }
      &:nth-child(5) {
        margin: 10px 0px;
      }
      &:nth-child(6) {
        margin-bottom: 40px;
      }
      &:nth-child(8) {
        margin: 10px 0px 0px 0px;
      }
      &:nth-child(9) {
        margin-top: 12px;
        margin-bottom: 50px;
      }
    }
  }
}
.SoulsModuleContainer {
  margin: 0 auto;
  width: 1530px;
  margin-bottom: 200px;
  @media (max-width: 1870px) {
    width: 1330px;
  }
  @media (max-width: 1690px) {
    width: 1200px;
  }
  @media (max-width: 1520px) {
    width: auto;
    margin-bottom: 58px;
  }
  .CorespText {
    position: relative;
    top: -15px;
    @media (max-width: 1520px) {
      // display: none;
      position: relative;
      top: auto;
      width: 900px;
      margin: 0 auto;
      padding-left: 135px;
      box-sizing: border-box;
      margin-top: 30px;
    }
    @media (max-width: 890px) {
      font-family: Sitka;
      font-size: 18px;
      font-weight: 400;
      line-height: 27.52px;

      width: 340px;
      padding: 0;
    }
    @media (max-width: 360px) {
      width: 90%;
    }

    .CorespTextTitle {
      font-style: italic;
    }
    p {
      text-align: left;
      color: #3e280e;
      font-size: 26px;
      margin: 0;
      @media (max-width: 890px) {
        font-family: Sitka;
        font-size: 18px;
        line-height: 10.52px;
      }
      &:nth-child(2) {
        margin-bottom: 15px;
        margin-top: 20px;
      }
      a {
        color: #3e280e;
        cursor: pointer;
        transition: 0.1s linear all;
        text-decoration: none;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
.imgBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1520px) {
    display: none;
  }
}
.imgBox {
  position: relative;
  img {
    &:first-child {
      margin-top: -100px;
      @media (max-width: 1870px) {
        margin-top: -220px;
      }
      @media (max-width: 1690px) {
        margin-top: -295px;
      }
    }
  }
}

.caracum {
  position: absolute;
  bottom: -100px;
  left: -180px;
}
